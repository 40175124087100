
<template>
  <head-new
      :page_title="state.isCreate ? 'Добавить' : ct('Action.Edit')"
      :back-action="() => router.push({name: $route.meta.returnName ?? 'EditProducts'})"
  />

  <div class="main_container form">
    <template v-if="state.product && state.product.status && subscriptionStatus === subscriptionStatuses.active">
      <Card style="margin-bottom: 24px">
        <Typography
            variant="subtitle2"
            :color="productsStatus[subscriptionStatus === subscriptionStatuses.active ? state.product.status : 'draft'].color"
        >
          {{ productsStatus[subscriptionStatus === subscriptionStatuses.active ? state.product.status : 'draft'].title }}
        </Typography>

        <Typography
            v-if="subscriptionStatus === subscriptionStatuses.active"
            variant="body1"
            style="margin-top: 4px"
        >
          После проверки продукт будет опубликован на ярмарке
        </Typography>
      </Card>
    </template>

    <ImageCover
        label="Обложка продукта"
        name="image"
        :imagePreview="state.imagePreview"
        :aspect-ratio="1.8"
    />

    <FormTextAreaGrowable
        label="Заголовок"
        name="title"
        :rows="2"
    />

    <FormTextAreaGrowable
        label="Описание"
        name="description"
        :rows="4"
    />

    <FormTextInput
        label="Цена"
        name="price"
        value="Бесплатно"
        text-info="Все продукты пока размещаются бесплатно"
        disabled
    />

    <Card class="publishedCard">
      Показывать на ярмарке
      <FormSwitch
          label=""
          name="is_published"
      />
    </Card>

    <Card
        v-if="!(state.subscriptionStatus in {[subscriptionStatuses.active]: true})"
        class="subscriptionBlock"
        style="margin-top: 8px; cursor: pointer"
        @click="openSubscription"
    >
      <div
          style="display: flex;"
          :style="{gap: `8px`}"
      >
        <Icon
            style="flex-shrink: 0"
            :icon="require(`@/assets/icons/_images/${20}_subscription.svg`)"
            :width="`${20}`"
            :height="`${20}`"
        />
        <Typography variant="v12-400" color="secondary">
          Публикация продуктов на ярмарке доступна в подписке «Younesis Premium»
        </Typography>
      </div>

      <Typography
          style="margin-top: 4px; margin-left: 28px; display: flex;"
          variant="v14-700"
          color="secondary"
      >
        Подключить
        <Icon
            rotate="270"
            :icon="require(`@/assets/icons/white_24_arrowDown.svg`)"
            height="20"
            width="20"
        />
      </Typography>
    </Card>

    <div class="buttons">
      <appButton
          full-width
          class="submitButton"
          :loading="isSubmitting"
          :disabled="isSubmitting"
          @click="onSubmit"
      >
        <template v-if="state.subscriptionStatus in {[subscriptionStatuses.active]: true}">
          {{ ct('Action.Save') }}
        </template>
        <template v-else>
          {{ values.is_published ? 'Подключить подписку' : 'Сохранить черновик' }}
        </template>
      </appButton>

      <appButton
          full-width
          class="submitButton"
          :disabled="isSubmitting"
          variant="secondary"
          @click="onDelete"
      >
        {{ state.isCreate ? ct('Action.Cancel') : ct('Action.Delete') }}
      </appButton>
    </div>

    <BottomDrawer
        :is-shown="state.isDeleteId"
        title="Удаление данных"
        @close="state.isDeleteId = null"
    >
      <Typography variant="body1" color="secondary">
        Ты точно хочешь удалить этот продукт?
      </Typography>

      <div class="modalButtons">
        <appButton
            variant="secondary"
            @click="state.isDeleteId = null"
        >
          Оставить
        </appButton>
        <appButton
            variant="redSecondary"
            @click="deleteProduct(state.isDeleteId)"
        >
          {{ ct('Action.Delete') }}
        </appButton>
      </div>

    </BottomDrawer>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store'
import {computed, onBeforeMount, onMounted, reactive, watch} from 'vue'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import {useRoute} from 'vue-router'
import {appAxios} from '@/axios'
import Typography from '@/components/UI/Typography.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import FormTextInput from '@/components/form/FormTextInput.vue'
import FormSwitch from '@/components/form/FormSwitch.vue'
import Card from '@/components/UI/Card.vue'
import ImageCover from '@/components/form/FormImageCover.vue'
import {productsStatus} from "@/configs/productsStatus.js"
import FormTextAreaGrowable from "@/components/form/FormTextAreaGrowable.vue"
import {subscriptionStatuses} from '@/configs/subscriptionStatuses.js'
import Icon from '@/components/UI/Icon.vue'
import {ct} from '../../../locales/i18nextInit.js'

const subscriptionStatus = computed(() => store.getters["user/subscriptionStatus"])

const props = defineProps({
  productId: {
    type: String,
  }
})

const route = useRoute()

const state = reactive({
  isCreate: route.meta.isCreate,
  isLoading: true,
  imagePreview: null,
  product: null,
  isDeleteId: null,
  subscriptionStatus: store.state.user.profile.subscriptions?.at(0)?.status ?? subscriptionStatuses.new
})

watch(() => store.state.user.profile.subscriptions, subscriptions => {
  state.subscriptionStatus = subscriptions.at(0)?.status ?? subscriptionStatuses.new
})

const {handleSubmit, values, isSubmitting, setFieldValue, setValues, setErrors} = useForm({
  validationSchema: Yup.object({
    title: Yup.string().trim().label('Заголовок').required().max(80),
    description: Yup.string().trim().label('Описание').required().max(300),
  }),
})

onBeforeMount(async () => {
  try {
    if (props.productId) {
      const res = await appAxios.products.get(props.productId)
      state.product = res.data
      state.imagePreview = state.product.image ? state.product.image : null
      setValues({
        title: state.product.title,
        description: state.product.description,
        is_published: subscriptionStatus === subscriptionStatuses.active ? state.product.is_published : false,
        image: state.product.image ? state.product.image : null,
      })
    }
  } catch (e) {
    // todo: error?
  } finally {
    state.isLoading = false
  }
})

onMounted(() => {
  if (state.isCreate) {
    setValues({
      title: null,
      description: null,
    })
    requestAnimationFrame(() => {
      setErrors({
        title: null,
        description: null,
      })
      requestAnimationFrame(() => {
        setErrors({
          title: null,
          description: null,
        })
        requestAnimationFrame(() => {
          setErrors({
            title: null,
            description: null,
          })
        })
      })
    })
  }
})

const onDelete = async function () {
  if (state.isCreate) {
    return router.push({
      name: route.meta.isCheckList ? 'CheckListEditProducts' : 'EditProducts',
    })
  } else {
    // const found = store.state.user.profile.products.find(item => item.id.toString() === props.productId.toString())
    state.isDeleteId = Number(props.productId)
  }
}

const onSubmit = handleSubmit(async values => {
  if (state.isCreate) {
    const payload = {
      ...values,
    }
    if (values.image instanceof window.File) {
      // fine
    } else {
      delete payload.image
    }
    await appAxios.products.create(payload)
  } else {
    const payload = {
      ...state.product,
      ...values,
    }
    if (values.image instanceof window.File) {
      // fine
    } else {
      delete payload.image
    }
    await appAxios.products.update(payload)
  }
  const resResult = await appAxios.products.fetchMyAll()

  await store.commit('user/setProfile', {
    products: resResult.data,
  })
  if(state.subscriptionStatus in {[subscriptionStatuses.active]: true}) {
    return await router.push({name: route.meta.returnName ?? 'EditProducts'})
  }
  if(!values.is_published) {
    return await router.push({name: route.meta.returnName ?? 'EditProducts'})
  }
  await openSubscription()
})

async function deleteProduct(id) {
  await appAxios.products.remove({id})

  const resResult = await appAxios.products.fetchMyAll()
  state.productsList = resResult.data

  state.isDeleteId = null
  await router.push({name: route.meta.isCheckList ? 'CheckListEditProducts' : 'EditProducts'})
}

async function openSubscription() {
  if(state.isCreate) {
    sessionStorage.setItem('SubscriptionPageSettings', JSON.stringify({
      returnName: 'CreateProduct',
    }))
  } else {
    sessionStorage.setItem('SubscriptionPageSettings', JSON.stringify({
      returnName: 'EditProduct',
      params: {productId: state.product.id}
    }))
  }
  await router.push({
    name: 'SubscriptionTariffSelection',
  })
}

</script>

<style lang="scss" scoped>

.form {
  margin-top: 16px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.modalButtons {
  display: flex;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 16px;

  & > * {
    flex-grow: 1;
  }
}

.publishedCard {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

</style>
