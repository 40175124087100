import i18next from 'i18next'

import common from './ru/common.json'
import validation from './ru/validation.json'
import glossary from './ru/glossary.json'

import networking from './ru/networking.json'
import collection from './ru/collection.json'
import chat from './ru/chat.json'
import cardholder from './ru/cardholder.json'
import calendar from './ru/calendar.json'
import marketplace from './ru/marketplace.json'
import study from './ru/study.json'
import guest from './ru/guest.json'

import locales from './locales.json'
import {deepMerge} from '@/utils/deepMerge.js'

const i18nextInitiatedPromise = i18next.init({
  lng: 'ru', // if you're using a language detector, do not define the lng option
  // defaultNS: "common",
  // debug: true,
  // appendNamespaceToMissingKey: true,
})

const namespaces = {
  common,
  validation,
  glossary,
  networking,
  collection,
  cardholder,
  chat,
  calendar,
  marketplace,
  study,
  guest,
}

Object.keys(namespaces).forEach(key => {
  i18next.addResourceBundle("ru", key, namespaces[key])
})

window.i18next = i18next

const i18nextUseNamespace = (ns) => {
  return (key, ...args) => {
    return i18next.t(key, {
      ...args,
      ns,
    })
  }
}

export const i18nextUpdateResources = (locales) => {
  Object.entries(locales).forEach(([lang, data]) => {
    Object.entries(data).forEach(([namespace, data]) => {
      i18next.addResourceBundle(lang, namespace, deepMerge([namespaces[namespace], data]))
    })
  })
}

const ct = i18nextUseNamespace('common')
const gt = i18nextUseNamespace('glossary')
const vt = i18nextUseNamespace('validation')

export {
  i18nextInitiatedPromise,
  i18nextUseNamespace,
  ct,
  gt,
  vt,
}
