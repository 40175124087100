<template>
  <div v-if="state.isReady" class="pageWrapper">
    <head-new
        :back-action="() => router.push({name: 'SubscriptionTariffSelection'})"
        title="Оформление подписки"
    />

    <div class="main_container pageWrapperOverflow">

      <div class="steps">
        <Typography variant="body2" color="#A6A5A5">
          Шаг {{ 2 }} из {{ 2 }}
        </Typography>

        <Logo variant="leadpay"/>
      </div>

      <BarProgress
          :completed="2"
          :barCount="2"
          color="#CF2E2E"
          style="margin-bottom: 24px"
      />

      <Typography variant="v24-700" style="margin-bottom: 8px">
        {{ state.subscriptionPrice.title }}
      </Typography>

      <Typography variant="v20-700" style="margin-bottom: 24px; display: flex; gap: 8px; align-items: center">
        {{ rubFormat.format(state.subscriptionPrice.final_price) }} ₽
        <Typography
            v-if="state.subscriptionPrice.discounted_price && Number(state.subscriptionPrice.discounted_price) < Number(state.subscriptionPrice.price)"
            class="discountLabel"
            variant="v12-500"
        >
          {{
            `-${(((Number(state.subscriptionPrice.price) - Number(state.subscriptionPrice.discounted_price)) / Number(state.subscriptionPrice.price)) * 100) | 0}%`
          }}
        </Typography>
      </Typography>

      <div style="display: flex; margin-bottom: 24px">
        <div style="flex-grow: 1;display: flex; flex-direction: column; align-items: flex-start; gap: 16px">
          <div class="cell">
            Имя
          </div>
          <div class="cell">
            Номер телефона
          </div>
          <div class="cell">
            Электронная почта
          </div>
        </div>
        <div style="flex-grow: 1;display: flex; flex-direction: column; align-items: flex-end; gap: 16px">
          <div class="cell right">
            {{ state.profile.first_name }} {{ state.profile.last_name }}
          </div>
          <div class="cell right">
            {{ state.profile.phone }}
          </div>
          <div
              class="cell right"
              style="display: flex; gap: 6px"
          >
            {{state.email}}
            <AppButton
                variant="transparent"
                style="margin: -14px"
                @click="router.push({name: 'SubscriptionEditEmail'})"
            >
              <Icon
                  :icon="require(`@/assets/icons/white_20_pen.svg`)"
                  width="16"
                  height="16"
              />
            </AppButton>
          </div>
        </div>
      </div>

      <Card style="margin-top: 24px; display: flex; justify-content: center; padding: 12px 14px">
        <PoweredByLeadPay width="160" height="32" />
      </Card>

      <AppButton
          full-width
          style="margin-top: 24px"
          size="large"
          @click="getSubscription"
          :loading="state.isSubscriptionLoading"
          :disabled="state.isSubscriptionLoading"
      >
        Оплатить
      </AppButton>

      <Typography
          style="margin-top: 16px;"
          variant="v12-400"
          color="#A6A5A5"
          center
      >
        Нажимая кнопку и отправляя данные формы, Вы даёте согласие на
        <AppLink
            @click="router.push({name: 'SubscriptionPersonalDataProcessing'})"
        >
          обработку персональных данных,
        </AppLink>
        соглашаетесь с
        <AppLink
            @click="router.push({name: 'SubscriptionPrivacyPolicy'})"
        >
          политикой конфиденциальности,
        </AppLink>
        <AppLink
            @click="router.push({name: 'SubscriptionUserAgreement'})"
        >
          пользовательским соглашением
        </AppLink>
        и
        <AppLink
            @click="router.push({name: 'SubscriptionOffer'})"
        >
          офертой.
        </AppLink>
      </Typography>
    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import Typography from "@/components/UI/Typography.vue"
import BarProgress from "@/components/BarProgress.vue"
import Logo from "@/components/logo/YounesisLogo.vue"
import Card from "@/components/UI/Card.vue"
import store from "@/store/store.js"
import AppButton from '@/components/UI/AppButton.vue'
import PoweredByLeadPay from '@/components/logo/PoweredByLeadPay.vue'
import {onBeforeMount, reactive} from 'vue'
import Icon from '@/components/UI/Icon.vue'
import {router} from '@/router/router.js'
import {appAxios} from '@/axios.js'
import AppLink from '@/components/AppLink.vue'
import {paymentStatuses} from '@/configs/subscriptionStatuses.js'

const rubFormat = new Intl.NumberFormat('ru-RU', {})

const state = reactive({
  isReady: false,
  subscriptionPrice: null,
  SubscriptionPageSettings: null,
  email: null,
  profile: null,
  isSubscriptionLoading: false,
})

onBeforeMount(async () => {
  const raw = sessionStorage.getItem('SubscriptionPageSettings')
  if(!raw) {
    return router.push({name: 'SubscriptionTariffSelection'})
  }
  const json = JSON.parse(raw)
  const found = store.state.user.directories.subscription_price_list.find(i => {
    return i.id === json?.subscriptionPriceId
  })
  if(!found) {
    return router.push({name: 'SubscriptionTariffSelection'})
  }
  found.final_price = found.discounted_price ?? found.price
  state.subscriptionPrice = found
  state.SubscriptionPageSettings = json
  state.email = json.email ?? store.state.user.profile.email

  state.profile = store.state.user.profile
  state.isReady = true
})

async function getSubscription() {
  state.isSubscriptionLoading = true
  try {
    const res = await appAxios.subscription.init(state.subscriptionPrice.id, store.state.user.directories.payment_type_list.at(0).id)
    if (!res.data.payment.payment_url) {
      if(res.data.payment.status === paymentStatuses.pending || res.data.payment.status === paymentStatuses.success) {
        await store.dispatch('user/updateProfile', {
          subscriptions: [res.data.subscription],
        })
        await router.push({name: 'SubscriptionControl'})
        return
      }
      throw new Error('bad payment link')
    }
    window.location.href = res.data.payment.payment_url
  } catch (error) {
    // if (error.response?.data?.detail && error.response.data.detail === 'Payment init failed') {
    //   state.shownError = true
    //   return
    // }
    throw error
  } finally {
    // state.isSubscriptionLoading = false
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  //min-height: calc(100vh - 60px);
  //max-height: calc(100vh - 60px);
  //min-height: calc(100svh - 60px);
  //max-height: calc(100svh - 60px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  overflow-y: auto;
  padding-bottom: 24px;
}

.discountLabel {
  background: #CF2E2E;
  display: inline-block;
  padding: 2px 6px;
  border-radius: 20px;
}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.cell {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--text-disabled-color);

  &.right {
    font-weight: 500;
  }
}

</style>
