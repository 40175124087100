<template>
  <button
      v-if="!isLoading"
      :class="{
        [variant]: variant,
        [size]: size,
        round,
        fullWidth,
        leftAlignment,
        empty: !slots.default()[0].children && !slots.default()[1]?.children,
        leftIcon: slots.default()[1]?.children,
      }"
      :style="{color}"
  >
    <template v-if="!state.loadingDisplay">
      <slot/>
    </template>
    <template v-if="state.loadingDisplay">
      <SpinLoader
          style="position: absolute"
          :size="size === 'tiny' ? 18 : 24"
          :color="color"
      />
      <div style="visibility: hidden">
        <slot/>
      </div>
    </template>
  </button>
  <Skeleton
      v-else
      :full-width="fullWidth"
      :width="width"
      :height="settings[size].height"
      :radius="settings[size].radius"
      :class="{
        [variant]: variant,
        [size]: size,
        round,
        fullWidth,
        leftAlignment,
        empty: !slots.default()[0].children && !slots.default()[1]?.children,
        leftIcon: slots.default()[1]?.children,
      }"
  />
</template>

<script setup>
import Skeleton from '@/components/UI/Skeleton.vue'
import {nextTick, reactive, useSlots, watch} from 'vue'
import SpinLoader from "@/components/SpinLoader.vue"

const slots = useSlots()

const settings = {
  tiny: {
    height: '28',
    radius: '6',
  },
  small: {
    height: '36',
    radius: '8',
  },
  medium: {
    height: '44',
    radius: '10',
  },
  large: {
    height: '48',
    radius: '12',
  },
  huge: {
    height: '56',
    radius: '14',
  },
}

const props = defineProps({
  variant: {
    type: String, // primary, secondary, red?, white, gray, darkGray, transparent, green
    default: 'primary',
    required: false,
  },
  size: {
    type: String, // large, medium, small
    default: 'medium',
    required: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  round: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
  },
  leftAlignment: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: '#FFF',
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const state = reactive({
  loadingDisplay: false,
})

watch(() => props.loading, () => {
  if (props.loading) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          state.loadingDisplay = !!props.loading
        })
      })
    })
  } else {
    state.loadingDisplay = false
  }
})

</script>

<style lang="scss" scoped>

.empty.empty {
  flex-shrink: 0;
}

button {
  position: relative;
  transition: background-color ease .3s, border-color ease .3s, color ease .3s;
  border: 1px transparent solid;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  &.leftAlignment.leftAlignment {
    justify-content: flex-start;

    &.large {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &.round.round {
    border-radius: 320px;
  }

  &:disabled {
    cursor: default;
  }

  &.fullWidth {
    width: 100%;
  }

  &.huge {
    height: 56px;
    min-width: 56px;
    border-radius: 14px;
    padding: 0 24px;
    font-size: 14px;

    &.leftIcon {
      gap: 8px;
    }

    &.empty {
      padding: 0 11px;
    }
  }


  &.large {
    height: 48px;
    min-width: 48px;
    border-radius: 12px;
    padding: 0 24px;
    font-size: 14px;

    &.leftIcon {
      gap: 8px;
    }

    &.empty {
      flex-basis: 48px;
      padding: 0 11px;
    }
  }

  &.medium {
    height: 44px;
    min-width: 44px;
    border-radius: 10px;
    padding: 0 16px;

    &.leftIcon {
      gap: 8px;
    }

    &.empty {
      padding: 0 8px;
    }
  }

  &.small {
    height: 36px;
    min-width: 36px;
    border-radius: 8px;
    padding: 0 16px;

    &.leftIcon {
      gap: 4px;
    }

    &.empty {
      padding: 0 4px;
    }
  }

  &.tiny {
    height: 28px;
    min-width: 28px;
    border-radius: 6px;
    padding: 0 6px;

    &.leftIcon {
      padding: 0 12px;
    }

    &.empty {

    }
  }

  &.primary {
    background: #CF2E2E;

    &:hover:not(:disabled) {
      background: #DE3939;
    }

    &:active:not(:disabled) {
      background: #C52828;
    }

    &:disabled {
      background: #852525;
      color: #c39292;
    }
  }

  &.secondary {
    background: var(--background-paper-light-color);
    border: 1px #494949 solid;

    &:hover:not(:disabled) {
      background: #393939;
      border: 1px #606060 solid;
    }

    &:active:not(:disabled) {
      background: #2C2B2B;
      border: 1px #232323 solid;
    }

    &:disabled {
      background: #323131;
      border: 1px #494949 solid;
      color: #9F9F9F;
      opacity: .8;
    }
  }

  &.redSecondary {
    background: var(--background-paper-light-color);
    border: 1px #494949 solid;
    color: #FF303A;

    &:hover:not(:disabled) {
      background: #393939;
      border: 1px #606060 solid;
    }

    &:active:not(:disabled) {
      background: #2C2B2B;
      border: 1px #232323 solid;
    }

    &:disabled {
      background: #323131;
      border: 1px #494949 solid;
      color: #9F9F9F;
      opacity: .8;
    }
  }

  &.white {
    background: #FFFFFF;
    border: 1px #FFFFFF solid;
    color: #1A1919;

    &:hover:not(:disabled) {
      background: #FFFFFF;
      border: 1px #FFFFFF solid;
    }

    &:active:not(:disabled) {
      background: #9F9F9F;
      border: 1px #9F9F9F solid;
    }

    &:disabled {
      background: #494949;
      border: 1px #494949 solid;
      color: #9F9F9F;
    }
  }

  &.gray {
    background: #5a5a5a;
    border: 1px #5a5a5a solid;
    color: var(--text-primary-color);

    &:hover:not(:disabled) {
      background: #696969;
      border: 1px #696969 solid;
    }

    &:active:not(:disabled) {
      background: #9F9F9F;
      border: 1px #9F9F9F solid;
    }

    &:disabled {
      background: #464646;
      border: 1px #464646 solid;
      color: #9F9F9F;
    }
  }

  &.green {
    background: #374337;
    border: 1px #374337 solid;
    color: #20B537;

    &:hover:not(:disabled) {
      background: #374337;
      border: 1px #374337 solid;
    }

    &:active:not(:disabled) {
      background: #485f48;
      border: 1px #485f48 solid;
    }

    &:disabled {
      background: #374337;
      border: 1px #374337 solid;
      color: #9F9F9F;
    }
  }

  &.darkGray {
    background: #313131;
    border: 1px #313131 solid;
    color: var(--text-primary-color);

    &:hover:not(:disabled) {
      background: #474747;
      border: 1px #474747 solid;
    }

    &:active:not(:disabled) {
      background: #9F9F9F;
      border: 1px #9F9F9F solid;
    }

    &:disabled {
      background: #464646;
      border: 1px #464646 solid;
      color: #9F9F9F;
    }
  }

  &.gold {
    background: linear-gradient(360deg, #FF6D2F 0%, #FFB802 100%);
    border: 2px #FFFFFF solid;
    color: #FFFFFF;

    &:hover:not(:disabled) {
      background: linear-gradient(360deg, #ff783e 0%, #ffbd11 100%);
      border: 2px #FFFFFF solid;
    }

    &:active:not(:disabled) {
      background: #ffbd11;
      border: 2px #FFFFFF solid;
    }

    &:disabled {
      opacity: .7;
    }
  }

  &.transparent {
    background: transparent;
    border: 2px transparent solid;
    color: #FFFFFF;

    &:hover:not(:disabled) {
      background: transparent;
      border: 2px transparent solid;
    }

    &:active:not(:disabled) {
      background: transparent;
      border: 2px transparent solid;
      opacity: .8;
    }

    &:disabled {
      background: transparent;
      border: 2px transparent solid;
      color: #9F9F9F;
      opacity: .7;
    }
  }

}
</style>
