<template>
  <div class="search main_container">

    <div class="head">
      <head-new
          :page_title="nt('Networking')"
          :back-action="() => router.push({name: $route.meta.returnName ?? 'Home'})"
          :rightIcon="require(`@/assets/icons/white_24_search.svg`)"
          :rightAction="() => router.push({name: 'BrowsingUserView'})"
      />
    </div>

    <div
        v-if="state.isLoading || state.active.user"
        class="content_wrap"
    >
      <BrowsingUserCard
          :isLoading="state.isLoading"
          v-show="state.back.user"
          :user="state.back.user"
          :class="state.back.classes"
          :classes="state.back.classes"
      />

      <BrowsingUserCard
          :isLoading="state.isLoading"
          v-show="state.next.user"
          :user="state.next.user"
          :class="state.next.classes"
          :classes="state.next.classes"
      />

      <BrowsingUserCard
          :isLoading="state.isLoading"
          v-show="state.active.user"
          :user="state.active.user"
          :class="state.active.classes"
          :classes="state.active.classes"
      />
    </div>

    <template
        v-if="state.isLoading || state.active.user"
    >
      <Evaluation
          v-if="!state.isLoading"
          type="adding"
          :autoClear="true"
          :userContact="{rating: 0}"
          @evaluated="evaluate"
          :disabled="state.isAction"
          style="position: relative; z-index: 4"
      />
      <div
          v-else
          style="display: flex;align-items: center;gap: 16px;justify-content: center;"
      >
        <Skeleton
            width="80"
            height="80"
            radius="40"
            style="flex-shrink: 0"
        />
        <Skeleton
            full-width
            height="80"
            radius="40"
        />
      </div>
    </template>
    <div
        v-else
        class="listIsEmpty"
    >
      <div class="userEmptyCards">
        <div class="userFakeCard"/>
        <div class="userFakeCard"/>
        <UserCard
            :is-loading="true"
            :data="null"
            hideActions
        />
      </div>

      <Typography variant="subtitle" center style="padding: 0 12px">
        {{ nt('AllProfilesViewedTitle') }}
      </Typography>

      <Typography variant="body1" center color="secondary" style="padding: 0 12px">
        {{ nt('AllProfilesViewedText') }}
      </Typography>

    </div>
  </div>
</template>

<script setup>
import {onBeforeMount, onBeforeUnmount, reactive} from 'vue'
import Evaluation from '@/components/Evaluation.vue'
import {router} from '@/router/router'
import BrowsingUserCard from '@/views/social/BrowsingUserCard.vue'
import {appAxios} from '@/axios'
import Skeleton from '@/components/UI/Skeleton.vue'
import Typography from '@/components/UI/Typography.vue'
import UserCard from '@/components/UserCard.vue'
import HeadNew from '@/components/Head.vue'
import {yandexMetrika} from '@/external/yandexWebisor.js'
import {i18nextUseNamespace} from '@/locales/i18nextInit.js'

const nt = i18nextUseNamespace('networking')

const props = defineProps({
  userId: {
    type: String,
  },
})

const state = reactive({
  isLoading: true,
  isAdditionalLoading: false,
  isAction: false,

  browsingList: [],
  checked: {},

  back: {
    classes: ['back'],
  },
  next: {
    classes: ['next'],
  },
  active: {
    classes: ['active'],
  },
  timeout1: null,
  timeout2: null,
  timeout3: null,
  startTime: new Date().getTime()
})

function sendTimeSpent() {
  if (state.startTime) {
    let endTime = new Date().getTime()
    let timeSpent = (endTime - state.startTime) / 1000
    yandexMetrika('reachGoal', 'networkingTimeSpent', {
      time: timeSpent,
    })
    state.startTime = null
  }
}

window.addEventListener("beforeunload", sendTimeSpent)

onBeforeUnmount(() => {
  clearTimeout(state.timeout1)
  clearTimeout(state.timeout2)
  sendTimeSpent()
  window.removeEventListener("beforeunload", sendTimeSpent)
})

onBeforeMount(async () => {
  try {
    const res = await appAxios.networking.fetch(5)
    state.browsingList = res.data

    state.active.user = getNext(props.userId)
    state.next.user = getNext()
    state.back.user = getNext()
  } catch (e) {
    console.error(e)
  } finally {
    state.isLoading = false
  }
})

function getNext(userId) {
  if (state.browsingList.length === 0) {
    return null
  }

  const used = {
    [state.active?.user?.id]: true,
    [state.next?.user?.id]: true,
    [state.back?.user?.id]: true,
  }
  const filteredList = state.browsingList.filter(user => !used[user.id])

  if (userId) {
    const foundIndex = filteredList.findIndex(user => String(user.id) === String(userId))
    if (foundIndex > -1) {
      return state.browsingList[foundIndex]
    }
  }
  if (filteredList.length) {
    return filteredList.at(0)
  }
  return null
}

async function additionalLoading() {
  if (state.isAdditionalLoading) {
    return null
  }
  state.isAdditionalLoading = true
  try {
    const res = await appAxios.networking.fetch(10)
    const add = res.data
        .filter(user => {
          if (user.id in state.checked) {
            console.log('already', user.id)
            return false
          }
          return !state.browsingList.find(u => u.id === user.id)
        })
    state.browsingList.push(...add)
  } finally {

    state.isAdditionalLoading = false
  }
}

async function evaluate(rate) {
  if (state.isAction || state.isLoading) {
    return
  }
  state.isAction = true
  state.browsingList = state.browsingList.filter(u => u.id !== state.active.user.id)

  let type = rate === 0 ? 'decline' : 'accept'

  state.back.classes = ['back', 'toNext']
  state.next.classes = ['next', 'toActive']
  state.active.classes = ['active', type]

  state.timeout1 = setTimeout(() => {
    const temp = state.active
    state.active = state.next
    state.next = state.back
    state.back = temp
    state.active.classes = ['active']
    state.next.classes = ['next']
    state.back.classes = ['back appear']
    state.back.user = getNext()
    // reset scroll
    document.querySelector('.active .content').scrollTop = 0

    state.timeout2 = setTimeout(() => {
      state.isAction = false
    }, 150)
  }, 700)

  const userId = state.active.user.id

  state.checked[userId] = true

  if (rate > 0) {
    await Promise.all([
      appAxios.myContacts.addContact(userId, rate),
      appAxios.networking.checkProfile(userId),
    ])
  } else {
    await appAxios.networking.checkProfileReject(userId)
  }

  if (state.browsingList.length < 5) {
    additionalLoading().then(() => null)
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.search {
  min-height: calc(100vh - 80px);
  min-height: calc(100svh - 80px);
  max-height: calc(100vh - 80px);
  max-height: calc(100svh - 80px);
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  //margin-bottom: -$homeLayoutPaddingBottom;
}

.head {
  //padding-top: $topOffset !important;;
  //margin-bottom: 36px;
  margin: 0 -16px 36px -16px;
  //display: flex;
  //align-items: center;
  //justify-content: space-between;
}

@keyframes backgroundAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: .12;
  }
}

@keyframes appear {
  0% {
    transform: scale(0.8);
    top: 24px;
  }
  100% {
    top: -24px;
    transform: scale(0.8);
  }
}

@keyframes toActive {
  0% {
    transform: scale(0.9);
    top: -12px;
  }
  60% {
    transform: scale(0.9);
    top: -12px;
  }
  100% {
    transform: scale(1);
    top: 0;
  }
}

@keyframes toNext {
  0% {
    top: -24px;
    transform: scale(0.8);
  }
  60% {
    top: -24px;
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.9);
    top: -12px;
  }
}

@keyframes decline {
  0% {
    background: var(--background-paper-color);
    opacity: 100%;
  }
  50% {
    right: 0;
    transform: rotate(-15deg);
  }
  100% {
    right: 500px;
    transform: rotate(-18deg);
  }
}

@keyframes accept {
  0% {
    transform: rotate(0);
    background: var(--background-paper-color);
  }
  50% {
    left: 0;
    transform: rotate(18deg);
  }
  100% {
    left: 500px;
    transform: rotate(18deg);
  }
}

.content_wrap {
  flex-grow: 1;
  margin-bottom: 24px;
  position: relative;

  .back {
    position: absolute;
    height: 100%;
    width: 100%;
    top: -24px;
    transform: scale(0.8);
    z-index: 1;
  }

  .next {
    position: absolute;
    height: 100%;
    width: 100%;
    top: -12px;
    transform: scale(0.9);
    z-index: 2;
  }

  .appear {
    animation-duration: .2s;
    animation-name: appear;
  }

  .active {
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--background-paper-color);
    z-index: 3;
  }

  .toNext {
    animation-duration: .7s;
    animation-name: toNext;
  }

  .toActive {
    animation-duration: .7s;
    animation-name: toActive;
  }

  .decline {
    transform-origin: bottom;
    animation-duration: .7s;
    animation-name: decline;

    &:after {
      content: 'Не интересует';
      color: var(--text-primary-color);
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      margin: 12px;
      padding: 12px 18px;
      border-radius: 8px;
      background: rgb(180, 64, 64);
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 3;
      display: block;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgb(255, 64, 64);
      animation: backgroundAnimation 1s forwards;
    }
  }

  .accept {
    transform-origin: bottom;
    animation-duration: .7s;
    animation-name: accept;

    &:after {
      content: 'В визитницу';
      color: var(--text-primary-color);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      margin: 12px;
      padding: 12px 18px;
      border-radius: 8px;
      background: rgb(64, 180, 64);
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 3;
      display: block;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgb(64, 255, 64);
      animation: backgroundAnimation 1s forwards;
    }
  }
}

.listIsEmpty {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 60px;
}

.userFakeCard {
  padding: 16px;
  border-radius: 20px;
  border: 1px solid #FFFFFF14;
  background: var(--background-paper-color);
  height: 220px;
}

.userEmptyCards {
  margin-bottom: 32px;
  position: relative;

  & > *:nth-child(1) {
    position: absolute;
    height: 100%;
    width: 100%;
    top: -48px;
    transform: scale(0.8);
    z-index: 1;
    background: #1B1B1B;
  }

  & > *:nth-child(2) {
    position: absolute;
    height: 100%;
    width: 100%;
    top: -24px;
    transform: scale(0.9);
    z-index: 2;
    background: #1F1F1F;
  }

  & > *:nth-child(3) {
    position: relative;
    z-index: 3;
  }
}

</style>
