<template>
  <div
      class="modal"
      :class="isShown ? 'modal_active' : ''"
      @click="check"
      ref="modalRef"
  >
    <div class="modal_in">
      <div v-if="closeButtonOutside" class="closeButtonOutside">
        <AppButton
            variant="secondary"
            @click="$emit('close')"
        >
          <Icon :icon="require(`@/assets/icons/white_24_cross.svg`)"/>
        </AppButton>
      </div>

      <div v-if="title" class="modal_top">
        <Typography variant="v16-700">
          {{ title }}
        </Typography>

        <button
            v-if="hasTitleClose"
            class="close_modal"
            @click="$emit('close')"
        >
          <img
              src="@/assets/images/modal_close.svg"
              alt=""
          />
        </button>
      </div>

      <slot/>
    </div>
  </div>
</template>

<script setup>
import {ref, watch} from 'vue'
import AppButton from '@/components/UI/AppButton.vue'
import Icon from '@/components/UI/Icon.vue'
import {useModalScrollingFix} from '@/hooks/useModalScrollingFix'
import Typography from "@/components/UI/Typography.vue";

const modalScrollingFix = useModalScrollingFix()

const modalRef = ref(null)

const emit = defineEmits(['close'])

const props = defineProps({
  closeOnClickAway: {
    type: Boolean,
    default: true,
  },
  isShown: {
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  hasTitleClose: {
    type: Boolean,
    default: true,
  },
  closeButtonOutside: {
    type: Boolean,
    default: false,
  }
})

watch(() => props.isShown, (value) => {
  if(value) {
    modalScrollingFix.open()
  } else {
    modalScrollingFix.close()
  }
})

function check(event) {
  if (event.target === modalRef.value && props.closeOnClickAway) {
    emit('close')
  }
}

</script>

<style lang="scss" scoped>
.modal {
  height: 0;
  width: 0;
  backdrop-filter: blur(13px);
  transform: translate3d(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: transform .5s, backdrop-filter .5s;

  .modal_in {
    max-width: min(calc(100% - 32px), 480px);
    width: 100%;
    border-radius: 16px;
    background: rgb(34, 34, 34);
    padding: 16px;
    transform: translateY(500px);
    transition: transform .5s, backdrop-filter .5s;

    .modal_top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--text-primary-color);
      letter-spacing: 0;
      margin-bottom: 20px;
    }
  }
}

.modal_active {
  opacity: 1;
  z-index: 1001;
  height: 100%;
  width: 100%;

  .modal_in {
    transform: translateY(0);
  }
}

.close_modal {
  margin-left: 12px;
}

.closeButtonOutside {
  position: absolute;
  right: 16px;
  top: -54px;
}
</style>
