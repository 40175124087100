<template>
  <div class="wrapper">
    <head-new
        page_title="Образование"
        :back-action="() => router.push({name: $route.meta.returnName ?? 'Profile'})"
    />
    <div class="main_container">
      <div class="form">
        <Card class="info">
          <Icon class="icon" width="20" height="20" :icon="require(`@/assets/icons/user_20_learning.svg`)"/>
          <Typography variant="body1">
            Информация о пройденном обучении: вузы, курсы, сообщества
          </Typography>
        </Card>

        <AppButton
            v-if="newEducationAvailable && education"
            full-width
            variant="primary"
            size="large"
            @click="createEducation"
            style="margin-bottom: 24px"
        >
          {{ ct('Action.Add') }}
        </AppButton>
      </div>

      <div v-if="education" class="educationList">
        <template v-for="educationType in educationTypeArray">
          <Card
              v-for="item in education[educationTypeToPlural(educationType)]"
              class="education"
          >
            <AppButton
                v-if="educationsCount > 1"
                variant="transparent"
                class="removeBtn"
                @click="setToDelete(item, educationType)"
            >
              <Icon :icon="require(`@/assets/icons/gray_16_trash.svg`)"/>
            </AppButton>

            <Typography variant="text1" style="padding-right: 24px" color="#A6A5A5">
              {{ item.degree ? item.degree : getEducationName(educationType) }}
            </Typography>

            <Typography variant="subtitle1" class="educationTitle">
              {{ item.title }}
            </Typography>

            <Typography v-if="item.ended_at" variant="body1" class="educationText">
              Год окончания: {{ item.ended_at ? item.ended_at.slice(0, 4) : '' }}
            </Typography>

            <div
                v-if="item.images && item.images.length"
                class="images"
            >
              <template v-for="(i, index) in item.images">
                <div
                    class="image"
                    :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(i.image))}"
                    v-fullscreen-image="{
                      panoramaCurrentIndex: index,
                      imageUrl: item.images.map(img => img.image),
                    }"
                />
              </template>
            </div>

            <appButton
                full-width
                variant="secondary"
                @click="editEducation(item.id, educationType)"
            >
              {{ ct('Action.Edit') }}
            </appButton>
          </Card>

        </template>
      </div>
      <div v-else class="middleText">
        <div>
          <Typography variant="subtitle">
            Здесь будет твое образование
          </Typography>

          <div style="height: 8px"/>

          <Typography variant="body1" color="secondary">
            Добавляй его и рассказывай про свой опыт
          </Typography>
        </div>

        <AppButton
            v-if="!education"
            full-width
            variant="primary"
            size="large"
            @click="createEducation"
            style="margin-top: 24px"
        >
          {{ ct('Action.Add') }}
        </AppButton>
      </div>


      <BottomDrawer
          :is-shown="state.isDelete"
          title="Удаление данных"
          @close="state.isDelete = null"
      >
        <Typography variant="body1" color="secondary">
          Ты действительно хочешь удалить данные об образовании?
        </Typography>

        <div class="modalButtons">

          <appButton
              variant="secondary"
              @click="state.isDelete = null"
          >
            Оставить
          </appButton>

          <appButton
              variant="redSecondary"
              @click="deleteEducation(state.isDelete)"
          >
            {{ ct('Action.Delete') }}
          </appButton>

        </div>

      </BottomDrawer>
    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import store from '@/store/store'
import {computed, onBeforeMount, onMounted, reactive, watch} from 'vue'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import Card from '@/components/UI/Card.vue'
import Typography from '@/components/UI/Typography.vue'
import Icon from '@/components/UI/Icon.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import {educationTypeArray, educationTypeToPlural, getEducationName} from '@/configs/educationType'
import moment from 'moment/moment'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import {appAxios} from "@/axios.js"
import {useRoute, useRouter} from "vue-router"
import {ct} from '../../../locales/i18nextInit.js'

const route = useRoute()

const state = reactive({
  isLoading: true,
  isDelete: null,
})

const education = computed(() => {
  return store.state.user.profile?.education?.at(0)
})

const educationLimits = store.state.user.directories.education_type_limit

const newEducationAvailable = computed(() => {
  if(!education.value) {
    return true
  }
  return education.value.courses.length < educationLimits.course || education.value.communities.length < educationLimits.community || education.value.institutions.length < educationLimits.institution
})

const educationsCount = computed(() => {
  if(!education.value) {
    return 0
  }
  return education.value.courses.length + education.value.communities.length + education.value.institutions.length
})

function createEducation() {
  router.push({
    name: route.meta.isCheckList ? 'CheckListCreateEducation' : 'CreateEducation',
  })
}

function setToDelete(item, educationType) {
  state.isDelete = {item, educationType}
}

function editEducation(id, educationType) {
  router.push({
    name: route.meta.isCheckList ? 'CheckListEditEducation' : 'EditEducation',
    params: {
      educationId: id,
      educationType: educationType,
    },
  })
}

async function deleteEducation({item, educationType}) {
  const res = await appAxios.educations.remove(item, educationType)
  await store.commit('user/setProfile', {
    education: [res.data],
  })
  state.isDelete = null
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.wrapper {
  min-height: 100svh;
  max-height: 100svh;
  display: flex;
  flex-direction: column;
  padding-bottom: $homeLayoutPaddingBottom + 56px;
  margin-bottom: -$homeLayoutPaddingBottom;
  overflow: auto;
}

.middleText {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.info {
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  gap: 8px;

  & > .icon {
    flex-shrink: 0;
  }
}


.educationList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.education {
  cursor: pointer;
  position: relative;
}

.educationTitle {
  margin-bottom: 16px;
  margin-top: 4px;
}

.educationText {
  margin-bottom: 16px;
  margin-top: 4px;
}

.removeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.modalButtons {
  display: flex;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 16px;

  & > * {
    flex-grow: 1;
  }
}

.images {
  display: flex;
  width: 100%;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 16px;

  .image {
    width: 44px;
    height: 56px;
    border-radius: 5px;
    background-color: #3B3B3B;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

</style>
