import {appType, appTypes} from '@/appType/appType.js'
import {settings} from '@/configs/settings.js'

export const pwaUtils = {
  isStandalone() {
    return appType === appTypes.TELEGRAM
      || window.matchMedia('(display-mode: standalone)').matches
      || window.matchMedia('(display-mode: minimal-ui)').matches
      || (navigator.standalone)
      || settings.env.VUE_APP_DISABLE_PWA_REQUIRED
      || localStorage.getItem('VUE_APP_DISABLE_PWA_REQUIRED')
  },

  isAndroidMobileOriginal() {
    const userAgent = navigator.userAgent
    const result = /Chrome\/(\d+\.\d+\.\d+\.\d+)/.exec(userAgent)
    if (result && result[1]) {
      const array = result[1].split('.')
      return array[1] === '0' && array[2] === '0' && array[3] === '0' && pwaUtils.isAndroidMobile() && pwaUtils.getBrowserName() === 'Chrome';
    }
    return false;
  },

  isAndroidMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    return /android/i.test(userAgent);
  },

  isAppleMobileOriginal() {
    const isAppleMobile = !!((
        ['iPhone', 'iPad', 'iPod'].includes(navigator.platform)
      )
      && (navigator.serviceWorker))

    if (!isAppleMobile) {
      return false
    }

    const ua = navigator.userAgent
    if (/(iPhone|iPod|iPad)/i.test(ua) && pwaUtils.getBrowserName() === 'Safari') {
      return ua.match(/OS [\d_]+/i)[0].substr(3).split('_').map(n => parseInt(n))
    }
    return [0] && pwaUtils.getBrowserName() === 'Safari'
  },

  isAppleMobile() {
    const isAppleMobile = !!(
      ['iPhone', 'iPad', 'iPod'].includes(navigator.platform)
    )

    if (!isAppleMobile) {
      return false
    }

    const ua = navigator.userAgent
    if (/(iPhone|iPod|iPad)/i.test(ua)) {
      return ua.match(/OS [\d_]+/i)[0].substr(3).split('_').map(n => parseInt(n))
    }
    return [0]
  },

  getBrowserName() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
      return 'Opera'
    } else if (navigator.userAgent.indexOf("Edg") !== -1) {
      return 'Edge'
    } else if (navigator.userAgent.indexOf("Chrome") !== -1 || navigator.userAgent.indexOf("CriOS") !== -1) {
      return 'Chrome'
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
      return 'Safari'
    } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      return 'Firefox'
    } else {
      return 'unknown'
    }
  },

  isAppleDesktop() {
    // check if it's a mac
    const userAgent = navigator.userAgent.toLowerCase()
    if (navigator.maxTouchPoints || !userAgent.match(/macintosh/))
      return false
    // check safari version >= 17
    const version = /version\/(\d{2})\./.exec(userAgent)
    if (!version || !version[1] || !(parseInt(version[1]) >= 17))
      return false
    // hacky way to detect Sonoma
    const audioCheck = !!document.createElement('audio').canPlayType('audio/wav; codecs="1"')
    const webGLCheck = !!new OffscreenCanvas(1, 1).getContext('webgl')

    return audioCheck && webGLCheck
  }
}
