<template>
  <head-new
      page_title="Роль"
      :back-action="() => router.push({name: $route.meta.returnName ?? 'EditProfile'})"
  />
  <div class="main_container form">

    <Card class="info">
      <Typography variant="body1">
        Как ты себя позиционируешь? Можешь поставить галочку в одном или нескольких пунктах
      </Typography>
    </Card>

    <FormError
        name="error"
        style="margin-bottom: 8px"
    />

    <Card
        v-for="specialization in specializationsList"
        style="margin-bottom: 8px"
        :variant="errors.error ? 'error' : 'secondary'"
    >
      <FormCircularCheckBox
          :name="`${specialization.id}`"
          :label="specialization.title"
          onlyInput
      />
    </Card>

    <Card
        :variant="errors.error ? 'error' : 'secondary'"
    >
      <FormCircularCheckBox
          :name="`${specializationProfession.id}`"
          :label="specializationProfession.title"
          onlyInput
      />

      <template v-if="values[specializationProfession.id]">
        <FormError
            v-show="errors.professionError"
            style="margin-top: 16px; margin-bottom: 14px"
            name="professionError"
        />

        <FormOptionSelect
            style="margin-top: 14px"
            class="select"
            name="profession1"
            placeholder="Профессия"
            :options="state.filteredProfessionList1"
            :canAcceptAnyValue="true"
            onlyInput
            :error="errors.professionError"
        />

        <FormOptionSelect
            class="select"
            name="profession2"
            placeholder="Профессия"
            :options="state.filteredProfessionList2"
            :canAcceptAnyValue="true"
            onlyInput
            :error="errors.professionError"
        />

        <FormOptionSelect
            class="select"
            name="profession3"
            placeholder="Профессия"
            :options="state.filteredProfessionList3"
            :canAcceptAnyValue="true"
            onlyInput
            :error="errors.professionError"
        />
      </template>
    </Card>

    <appButton
        class="submitButton"
        full-width
        :loading="isSubmitting"
        :disabled="isSubmitting"
        @click="onSubmit"
    >
      {{ ct('Action.Save') }}
    </appButton>

  </div>

</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store'
import {onBeforeMount, onMounted, reactive, watch} from 'vue'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import Card from '@/components/UI/Card.vue'
import Typography from '@/components/UI/Typography.vue'
import FormOptionSelect from '@/components/form/FormOptionSelect.vue'
import {appAxios} from '@/axios'
import FormCircularCheckBox from "@/components/form/FormCircularCheckBox.vue"
import {useRoute} from "vue-router";
import FormError from "@/components/form/FormError.vue";
import {ct} from '../../../locales/i18nextInit.js'

const route = useRoute()

const state = reactive({
  isLoading: true,
  professionList: [],
  filteredProfessionList1: [],
  filteredProfessionList2: [],
  filteredProfessionList3: [],
})

const allSpecializations = store.state.user.directories.specialization_list
const specializationsList = store.state.user.directories.specialization_list.filter(s => s.title !== 'Профессионал')
const specializationProfession = store.state.user.directories.specialization_list.find(s => s.title === 'Профессионал')

const {handleSubmit, setErrors, values, errors, isSubmitting, setValues} = useForm({
  validationSchema: Yup.object({
    profession1: Yup.string()
        .label('Профессия')
        .test('profession1', 'Профессия должна быть не более 40', (v) => {
          if(v) {
            const val = v.replace('|isNew|', '')
            return val.length <= 40
          }
          return true
        }),
    profession2: Yup.string()
        .label('Профессия')
        .test('profession2', 'Профессия должна быть не более 40', (v) => {
          if(v) {
            const val = v.replace('|isNew|', '')
            return val.length <= 40
          }
          return true
        }),
    profession3: Yup.string()
        .label('Профессия')
        .test('profession3', 'Профессия должна быть не более 40', (v) => {
          if(v) {
            const val = v.replace('|isNew|', '')
            return val.length <= 40
          }
          return true
        }),
    error: Yup.mixed()
        .test('test1', 'Выбери хотя бы одну роль', () => {
          return allSpecializations.some(s => values[s.id])
        }),
    professionError: Yup.mixed()
        .test('test2', 'Заполни хотя бы одно поле', (_, context) => {
          const values = context.parent
          if (!values[specializationProfession.id]) {
            setErrors({
              professionError: null,
            })
            return true
          }
          return values[specializationProfession.id] && (values.profession1 || values.profession2 || values.profession3)
        }),
  }),
})

watch(values, (values) => {
  const used = {
    [values.profession1]: true,
    [values.profession2]: true,
    [values.profession3]: true,
  }

  state.filteredProfessionList1 = state.professionList.filter(profession => {
    if (values.profession1 === profession.value) {
      return true
    }
    return !used[profession.value]
  })

  state.filteredProfessionList2 = state.professionList.filter(profession => {
    if (values.profession2 === profession.value) {
      return true
    }
    return !used[profession.value]
  })

  state.filteredProfessionList3 = state.professionList.filter(profession => {
    if (values.profession3 === profession.value) {
      return true
    }
    return !used[profession.value]
  })
})

onBeforeMount(() => {
  state.professionList = [
    ...store.state.user.directories.profession_list.map(profession => {
      return {
        value: profession.id,
        label: profession.title,
      }
    }),
    ...store.state.user.profile.professions.map(userProfession => {
      return {
        value: userProfession.profession.id,
        label: userProfession.profession.title,
      }
    })
  ]
})

onMounted(() => {
  const [p1, p2, p3] = store.state.user.profile.professions
  const specializations = store.state.user.profile.specializations
  let values = {}
  specializations.forEach(us => values[us.specialization.id] = true)

  setValues({
    profession1: p1 ? p1.profession.id : undefined,
    profession2: p2 ? p2.profession.id : undefined,
    profession3: p3 ? p3.profession.id : undefined,
    ...values,
  })
  requestAnimationFrame(() => {
    setValues({
      profession1: p1 ? p1.profession.id : undefined,
      profession2: p2 ? p2.profession.id : undefined,
      profession3: p3 ? p3.profession.id : undefined,
      ...values,
    })
    requestAnimationFrame(() => {
      setErrors({
        error: null,
      })
      requestAnimationFrame(() => {
        setErrors({
          error: null,
        })
      })
    })
  })
})

const onSubmit = handleSubmit(async values => {
  const selectedProfessions = []
  if (values.profession1) {
    selectedProfessions.push(values.profession1)
  }
  if (values.profession2) {
    selectedProfessions.push(values.profession2)
  }
  if (values.profession3) {
    selectedProfessions.push(values.profession3)
  }

  const specializations = store.state.user.profile.specializations
  const specializationsToRemove = []
  const specializationsToAdd = []

  allSpecializations.forEach(specialization => {
    const found = specializations.find(us => us.specialization.id === specialization.id)
    if (values[`${specialization.id}`]) {
      if (!found) {
        specializationsToAdd.push(specialization.id)
      }
    } else {
      if (found) {
        specializationsToRemove.push(found.id)
      }
    }
  })

  const professionsRes = await appAxios.profession.fetchAll()
  const userProfessions = professionsRes.data

  const toAdd = []
  const toRemove = []

  selectedProfessions.forEach(professionId => {
    const found = userProfessions.find(userProfession => userProfession.profession.id === professionId)
    if (found) {
      return
    }
    if (professionId.toString().includes('|isNew|')) {
      toAdd.push({
        title: professionId.replace('|isNew|', '')
      })
    } else {
      const found = store.state.user.directories.profession_list.find(p => p.id === professionId)
      if (found) {
        toAdd.push({
          title: found.title,
        })
      }
    }
  })

  userProfessions.forEach(userProfession => {
    const found = selectedProfessions.find(professionId => userProfession.profession.id === professionId)
    if (found) {
      return
    }
    toRemove.push(userProfession)
  })

  const promisesRemove = [
    ...toRemove.map(profession => appAxios.profession.remove(profession.id)),
    ...specializationsToRemove.map(id => appAxios.specializations.remove(id)),
  ]
  await Promise.all(promisesRemove)

  const promises = [
    ...toAdd.map(profession => appAxios.profession.create(profession.title)),
    ...specializationsToAdd.map(id => appAxios.specializations.add(id)),
  ]

  await Promise.all(promises)
  const res = await appAxios.profession.fetchAll()

  await store.commit('user/setProfile', {
    professions: res.data,
  })

  await router.push({name: route.meta.isCheckList ? 'CheckList' : 'EditProfile'})
})

</script>

<style lang="scss" scoped>
.info {
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  gap: 8px;

  & > .icon {
    flex-shrink: 0;
  }
}

.submitButton {
  margin-top: 32px;
}

</style>
