<template>
  <auth-default>
    <div v-show="!state.send_msg" class="register_wrapper">
      <div class="auth_head">
        <Logo/>
      </div>
      <h1 class="title">
        {{t("guest:Register.Title")}}
      </h1>
      <p class="description">
        {{t("guest:Register.Text")}}
      </p>

      <div class="main_container">
        <div class="register_inputs">
          <TextInput
              name="phone"
              type="phone"
              :label="ct('Label.PhoneNumber')"
              autocomplete="tel"
              placeholder=""
              :mask="state.phoneMask"
              pattern="[0-9]*"
              inputmode="tel"
              dataMaskaTokens="#:[0-9]|T:[0-9+]"
              @paste="e => {
                 let paste = (e.clipboardData || window.clipboardData).getData('text');
                 let cleared = paste.trim()
                 if(paste.at(0) === '8') {
                   cleared = paste.replace('8', '+7')
                 }
                 setFieldValue('phone', cleared)
              }"
          />
        </div>

        <AppButton
            full-width
            :loading="isSubmitting"
            :disabled="isSubmitting"
            @click="onSubmit"
            type="submit"
        >
          {{t("guest:Action.Register")}}
        </AppButton>

        <!--        <Card style="margin-top: 12px">-->
        <!--          <typography variant="v14-400">-->
        <!--            Если у тебя иностранный номер телефона, введи его в поле выше и переходи к следующему шагу. Что делать-->
        <!--            дальше, расскажем там-->
        <!--            &lt;!&ndash;            Если у тебя иностранный номер телефона, обратись в нашу&ndash;&gt;-->
        <!--            &lt;!&ndash;            <AppLink&ndash;&gt;-->
        <!--            &lt;!&ndash;                color="#FF303A"&ndash;&gt;-->
        <!--            &lt;!&ndash;                @click="clickOpenURL(store.state.app.info.support_chat_link.link)"&ndash;&gt;-->
        <!--            &lt;!&ndash;                style="display: inline"&ndash;&gt;-->
        <!--            &lt;!&ndash;            >&ndash;&gt;-->
        <!--            &lt;!&ndash;              службу поддержки,&ndash;&gt;-->
        <!--            &lt;!&ndash;            </AppLink>&ndash;&gt;-->
        <!--            &lt;!&ndash;            что делать расскажем там&ndash;&gt;-->
        <!--          </typography>-->
        <!--        </Card>-->

        <div class="register_link">
          <span>
            {{t("guest:Register.AlreadyHaveAccount")}}
          </span>
          <router-link :to="{ name: 'LogIn' }" class="link">
            {{t("guest:Action.Login")}}
          </router-link>
        </div>

        <div style="flex-grow: 1"/>

        <Typography variant="text2" style="margin-top: 24px; margin-bottom: 24px">
          Продолжая, вы принимаете условия
          <AppLink
              @click="router.push({name: 'UserAgreement'})"
          >
            пользовательского соглашения
          </AppLink>
          а так же даете согласие на
          <AppLink
              @click="router.push({name: 'PersonalDataProcessing'})"
          >
            обработку персональных данных,
          </AppLink>
          <AppLink
              @click="router.push({name: 'InformationAdvertisingPolicy'})"
          >
            информационную и рекламную рассылку,
          </AppLink>
          <AppLink
              @click="router.push({name: 'PrivacyPolicy'})"
          >
            политику конфиденциальности.
          </AppLink>
        </Typography>

      </div>
    </div>

    <!--    <OtpCode-->
    <!--        v-if="state.send_msg"-->
    <!--        :phone="state.phone"-->
    <!--        :error="state.error"-->
    <!--        v-model:show="state.send_msg"-->
    <!--        v-model:msg_code="state.msg_code"-->
    <!--    />-->

    <CheckMsg
        v-if="state.send_msg"
        :phone="state.phone"
        :error="!!state.error"
        :errorMsg="state.error"
        v-model:show="state.send_msg"
        v-model:msg_code="state.msg_code"
    />
  </auth-default>
</template>

<script setup>
import AuthDefault from '@/views/auth/AuthDefault.vue'
import CheckMsg from '@/views/auth/CheckMsg.vue'
import {appAxios} from '@/axios.js'
import HeadNew from '@/components/Head.vue'
import TextInput from '@/components/form/FormTextInput.vue'
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import {onMounted, onUnmounted, reactive, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'
import AppButton from '@/components/UI/AppButton.vue'
import {useOneSignal} from '@onesignal/onesignal-vue3'
import Typography from "@/components/UI/Typography.vue"
import AppLink from "@/components/AppLink.vue"
import Card from "@/components/UI/Card.vue"
import Logo from "@/components/UI/Logo.vue"
import * as libPhoneNumber from "libphonenumber-js"
import {yandexMetrika} from "@/external/yandexWebisor.js";
import {t} from 'i18next'
import {ct} from '@/locales/i18nextInit.js'

const router = useRouter()
const route = useRoute()
const store = useStore()

const z = useOneSignal()

const state = reactive({
  send_msg: false,
  error: '',
  phone: '',
  msg_code: '',
  ac: null,
  phoneMask: '+######################'
})

watch(
    () => state.msg_code,
    (msg_code) => {
      if (msg_code.length === 4) {
        checkCode()
      } else {
        state.error = ''
      }
    }
)

onUnmounted(() => {
  if (state.ac) {
    state.ac.abort('abort previous otp code request')
  }
})

async function checkCode() {
  try {
    const res = await appAxios.auth.checkRegisterCode(state.phone, state.msg_code)
    await store.dispatch('user/auth', {token: res.data.token})
    yandexMetrika('reachGoal', 'userRegister', {
      id: store.state.user.profile?.id,
    })
  } catch (err) {
    if (err?.response?.data?.detail) {
      state.error = err.response.data.detail
    }
  }
}

const {handleSubmit, setErrors, values, isFieldValid, isSubmitting, setFieldValue, isValidating, setValues} = useForm({
  validationSchema: Yup.object({
    phone: Yup.string().trim().label('Номер телефона')
        .required()
        .test('phone', 'Некорректный номер телефона', value => {
          try {
            const phone = libPhoneNumber.parsePhoneNumber(value)
            return phone.isValid()
          } catch (e) {
            return false
          }
        }),
  }),
})

watch(values, values => {
  try {
    const value = String(values.phone).replaceAll(' ', '')
    let newMask = new libPhoneNumber
        .AsYouType()
        .input(value)
        .toString()
        .replace(/\d/g, '#')
    newMask = `+${newMask.replace('+', '')}######################`
    if (newMask !== state.phoneMask) {
      state.phoneMask = newMask
    }
  } catch (e) {
    state.phoneMask = '+######################'
  }
})

watch(() => [values.phone, isValidating], ([phone, isValidating]) => {
  if (phone && !isValidating.value) {
    try {
      const value = libPhoneNumber.parsePhoneNumber(phone)
      if(value.isValid()) {
        sessionStorage.setItem('loginTel', phone)
      }
    } catch (e) {

    }
  }
})

onMounted(() => {
  const phone = sessionStorage.getItem('loginTel')
  if (!phone) {
    return
  }
  setValues({
    phone
  })
  requestAnimationFrame(() => {
    setErrors({
      phone: null,
    })
    requestAnimationFrame(() => {
      setErrors({
        phone: null,
      })
      requestAnimationFrame(() => {
        setErrors({
          phone: null,
        })
      })
    })
  })
})

const onSubmit = handleSubmit(async values => {
  try {
    const res = await appAxios.auth.register({
      ...values,
    })

    if (res && res.data.phone) {
      state.send_msg = true
      state.phone = values.phone
      // let send_code = await appAxios.auth.sendRegisterCode(res.data.phone)

      if (state.ac) {
        state.ac.abort('abort previous otp code request')
      }
      state.ac = new AbortController()

      if (navigator.credentials) {
        navigator.credentials
            .get({
              otp: {transport: ['sms']},
              signal: state.ac.signal,
            })
            .then((otp) => {
              state.msg_code = otp.code
            })
            .catch((err) => {
              console.error(err)
            })
      }
    }
  } catch (err) {
    if (err?.response?.data) {
      setErrors({
        ...err?.response?.data,
        phone: err?.response?.data?.phone ?? err.response.data.detail,
      })
    }
  }
})

function clickOpenURL(url) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: url,
  }).click();
}

</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.register_inputs {
  display: flex;
  flex-direction: column;
  //gap: 16px;
}

.register_wrapper {
  min-height: 100vh;
  min-height: 100svh;

  display: flex;
  flex-direction: column;

  .main_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .appLink {
    display: inline;
  }

  .error_text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    color: rgb(255, 48, 58);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0%;
    text-align: left;
    background: transparent;
    padding: 0;
    margin-bottom: 24px;
  }
}

.register_link {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.register_link span {
  color: var(--text-disabled-color);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.register_link .link {
  color: #cf2e2e;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-decoration: none;
  text-align: center;
}

.dark-theme .register_link .link {
  color: var(--text-primary-color);
}

@media only screen and (max-width: 992px) {
  .register_link {
    justify-content: center;
    margin-top: 32px;
  }
}

.title.title {
  color: var(--text-primary-color);
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 8px;
}

.description.description {
  color: var(--text-disabled-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 24px;
}
</style>
