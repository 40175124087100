<template>
  <Card class="info" :class="{[variant]: variant}">
    <div class="header">
      <Icon class="icon" width="20" height="20" :icon="require(`@/assets/icons/user_20_showcase.svg`)"/>

      <Typography variant="subtitle1">
        Продукты
      </Typography>

      <AppButton
          v-if="isEdit"
          class="btn"
          size="small"
          variant="transparent"
          @click="() => router.push({name: 'EditProducts'})"
      >
        <Icon
            :icon="products.length ? require(`@/assets/icons/gray_20_pen.svg`) : require(`@/assets/icons/red_20_add.svg`)"
        />
      </AppButton>
    </div>

    <div
        v-if="products.length"
        class="tags"
        ref="wrapperRef"
    >
      <template v-for="(item) in products">
        <div class="tag">

          <div
              v-if="item.preview_image"
              class="imgWrapper"
              :style="{height: `${((wrapperRef?.offsetWidth / 1.8) | 0) - 24}px`}"
          >
            <div
                class="img"
                :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(item.preview_image))}"
            />
          </div>

          <Typography variant="text1">
            Бесплатный продукт
          </Typography>

          <Typography variant="subtitle1" class="productTitle">
            {{ item.title }}
          </Typography>

          <Typography variant="body1" class="productText" style="white-space: pre-wrap;">
            {{ item.description }}
          </Typography>

          <AppButton
              v-if="canBeObtained"
              class="btnGet"
              @click="$emit('selectProduct', item)"
          >
            Получить
          </AppButton>
        </div>
      </template>
    </div>

  </Card>
</template>

<script setup>
import Card from '@/components/UI/Card.vue'
import Icon from '@/components/UI/Icon.vue'
import Typography from '@/components/UI/Typography.vue'
import {reactive, ref} from 'vue'
import AppButton from '@/components/UI/AppButton.vue'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import {router} from '@/router/router'

const wrapperRef = ref(null)

defineEmits(['selectProduct'])

const props = defineProps({
  variant: {
    type: String,
    required: false,
  },
  products: {
    type: Array,
    default: [],
  },
  canBeObtained: {
    type: Boolean,
    delete: false,
  },
  isEdit: {
    type: Boolean,
    default: false,
  }
})

const state = reactive({})
</script>

<style lang="scss" scoped>

.info {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.light {
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  position: relative;

  .btn {
    position: absolute;
    top: -9px;
    right: -9px;
  }

  & > .icon {
    flex-shrink: 0;
  }
}

.tags {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;

  .tag {
    padding: 12px;
    background: var(--background-paper-light-color);
    border-radius: 12px;
  }
}

.info.light {
  .tags {
    .tag {
      background: rgba(255, 255, 255, 0.08);
    }
  }
}

.productTitle {
  margin: 4px 0;
}

.imgWrapper {
  width: 100%;
  border-radius: 4px;
  background: #3B3B3B;
  margin-bottom: 12px;
  display: flex;

  .img {
    width: 100%;
    flex-shrink: 0;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.btnGet {
  margin-top: 12px;
}


</style>
