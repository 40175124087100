<template>
  <head-new
      page_title="Ниша/деятельность"
      :back-action="() => router.push({name: $route.meta.returnName ?? 'Profile'})"
  />
  <div class="main_container form">

    <Card class="info">
      <Icon class="icon" width="20" height="20" :icon="require(`@/assets/icons/user_20_segment.svg`)"/>

      <Typography variant="body1">
        В каких нишах у тебя есть опыт работы?
      </Typography>
    </Card>

    <Card>
      <FormError
          v-show="errors.error"
          style="margin-bottom: 14px"
          name="error"
      />

      <FormOptionSelect2
          class="select"
          name="niche1"
          placeholder="Например, здоровье"
          :options="state.filteredNicheList1"
          :canAcceptAnyValue="true"
          only-input
      />

      <FormOptionSelect2
          v-show="values.niche1"
          class="select"
          name="niche2"
          placeholder="Например, финансы"
          :options="state.filteredNicheList2"
          :canAcceptAnyValue="true"
          only-input
      />

      <FormOptionSelect2
          v-show="values.niche2"
          class="select"
          name="niche3"
          placeholder="Например, инфобизнес"
          :options="state.filteredNicheList3"
          :canAcceptAnyValue="true"
          only-input
      />
    </Card>

    <appButton
        class="submitButton"
        full-width
        :loading="isSubmitting"
        :disabled="isSubmitting"
        @click="onSubmit"
    >
      {{ ct('Action.Save') }}
    </appButton>

  </div>

</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store'
import {onBeforeMount, onMounted, reactive, watch} from 'vue'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import Card from '@/components/UI/Card.vue'
import Typography from '@/components/UI/Typography.vue'
import Icon from '@/components/UI/Icon.vue'
import {appAxios} from '@/axios'
import {useRoute} from "vue-router"
import FormError from "@/components/form/FormError.vue"
import FormOptionSelect2 from "@/components/form/FormOptionSelect2.vue"
import {ct} from '../../../locales/i18nextInit.js'

const route = useRoute()

const state = reactive({
  isLoading: true,
  nicheList: [],
  filteredNicheList1: [],
  filteredNicheList2: [],
  filteredNicheList3: [],
})

const {handleSubmit, setErrors, errors, values, isSubmitting, setValues} = useForm({
  validationSchema: Yup.object({
    niche1: Yup.string()
        .trim()
        .label('Ниша')
        .test('niche1', 'Ниша должна быть не более 40', (v) => {
          if(v) {
            const val = v.replace('|isNew|', '')
            return val.length <= 40
          }
          return true
        }),
    niche2: Yup.string()
        .trim()
        .label('Ниша')
        .test('niche2', 'Ниша должна быть не более 40', (v) => {
          if(v) {
            const val = v.replace('|isNew|', '')
            return val.length <= 40
          }
          return true
        }),
    niche3: Yup.string()
        .trim()
        .label('Ниша')
        .test('niche3', 'Ниша должна быть не более 40', (v) => {
          if(v) {
            const val = v.replace('|isNew|', '')
            return val.length <= 40
          }
          return true
        }),
    error: Yup.mixed()
        .test('error', 'Заполни хотя бы одно поле', () => {
          return values.niche1 || values.niche2 || values.niche3
        })
  }),
})

watch(values, (values) => {

  const used = {
    [values.niche1]: true,
    [values.niche2]: true,
    [values.niche3]: true,
  }

  let changed = false
  let newValues = {
    ...values,
  }

  if (!newValues.niche2 && newValues.niche3) {
    changed = true
    newValues = {
      niche1: newValues.niche1,
      niche2: newValues.niche3,
      niche3: newValues.niche2,
    }
  }

  if (!newValues.niche1 && newValues.niche2) {
    changed = true
    newValues = {
      niche1: newValues.niche2,
      niche2: newValues.niche1,
      niche3: newValues.niche3,
    }
  }

  if(changed) {
    setValues(newValues)
    return
  }

  state.filteredNicheList1 = state.nicheList.filter(niche => {
    if (values.niche1 === niche.value) {
      return true
    }
    return !used[niche.value]
  })

  state.filteredNicheList2 = state.nicheList.filter(niche => {
    if (values.niche2 === niche.value) {
      return true
    }
    return !used[niche.value]
  })

  state.filteredNicheList3 = state.nicheList.filter(niche => {
    if (values.niche3 === niche.value) {
      return true
    }
    return !used[niche.value]
  })
})

onBeforeMount(() => {
  state.nicheList = [
    ...store.state.user.directories.niche_list.map(niche => {
      return {
        value: niche.id,
        label: niche.title,
      }
    }),
    ...store.state.user.profile.niches.map(userNiche => {
      return {
        value: userNiche.niche.id,
        label: userNiche.niche.title,
      }
    })
  ]
})

onMounted(() => {
  const [p1, p2, p3] = store.state.user.profile.niches
  setValues({
    niche1: p1 ? p1.niche.id : undefined,
    niche2: p2 ? p2.niche.id : undefined,
    niche3: p3 ? p3.niche.id : undefined,
  })
})

const onSubmit = handleSubmit(async values => {
  const selectedNiches = []
  if (values.niche1) {
    selectedNiches.push(values.niche1)
  }
  if (values.niche2) {
    selectedNiches.push(values.niche2)
  }
  if (values.niche3) {
    selectedNiches.push(values.niche3)
  }

  const nichesRes = await appAxios.niche.fetchAll()
  const userNiches = nichesRes.data

  const toAdd = []
  const toRemove = []

  selectedNiches.forEach(nicheId => {
    const found = userNiches.find(userNiche => userNiche.niche.id === nicheId)
    if (found) {
      return
    }
    if (nicheId.toString().includes('|isNew|')) {
      toAdd.push({
        title: nicheId.replace('|isNew|', '')
      })
    } else {
      const found = store.state.user.directories.niche_list.find(p => p.id === nicheId)
      if (found) {
        toAdd.push({
          title: found.title,
        })
      }
    }
  })

  userNiches.forEach(userNiche => {
    const found = selectedNiches.find(nicheId => userNiche.niche.id === nicheId)
    if (found) {
      return
    }
    toRemove.push(userNiche)
  })

  const promisesRemove = [
    ...toRemove.map(item => appAxios.niche.remove(item.id)),
  ]
  await Promise.all(promisesRemove)
  const promisesAdd = [
    ...toAdd.map(item => appAxios.niche.create(item.title)),
  ]
  await Promise.all(promisesAdd)

  const res = await appAxios.niche.fetchAll()

  await store.commit('user/setProfile', {
    niches: res.data,
  })
  await router.push({name: route.meta.isCheckList ? 'CheckList' : 'Profile'})
})

</script>

<style lang="scss" scoped>
.info {
  margin-top: 16px;
  margin-bottom: 24px;
  display: flex;
  gap: 8px;

  & > .icon {
    flex-shrink: 0;
  }
}

.submitButton {
  margin-top: 32px;
}

//.select {
//  margin-bottom: -24px;
//}
</style>
