<template>
  <div>
    <Typography
        variant="h2"
        style="margin-bottom: 8px"
    >
      Расскажи немного о себе!
    </Typography>

    <Typography
        variant="v14-400"
        style="margin-bottom: 8px"
        color="#A6A5A5"
    >
      Эти данные помогут создать твой профиль.
    </Typography>

    <div
        style="position: relative"
    >
      <FormImageAvatar
          name="avatar"
          label="Аватарка"
          :image-preview="state.imagePreview"
      />
    </div>

    <FormTextInput
        name="last_name"
        label="Фамилия"
    />

    <FormTextInput
        name="first_name"
        label="Имя"
    />

    <FormTextInput
        name="email"
        label="Электронная почта"
    />

    <FormSelect
        name="city"
        label="Город"
        :options="state.cityList"
    />

    <FormTextArea
        class="textArea"
        label="О себе"
        name="about_me"
        :text-info="`${values.about_me?.length ?? '0'}/1000 символов`"
        :rows="6"
    />

    <Typography
        variant="v14-400"
        color="secondary"
        style="margin-bottom: 8px; margin-top: 4px"
    >
      Что пишут о себе другие:
    </Typography>

    <Card>
      <div style="display: flex; gap: 8px ;align-items: center">
        <Avatar
            size="tiny"
            radius="8"
            :image="require(`@/assets/images/profile/avatar.png`)"
        />
        <Typography
            variant="v18-700"
        >
          Наталия Зенцова
        </Typography>
      </div>

      <Typography
          variant="v14-500"
          color="secondary"
          style="padding-top: 12px;"
      >
        Маркетолог-стратег, продюсер онлайн курсов, достигатор💯
        <br/>
        В работе всегда опираюсь на стратегию, систему, цифры, смыслы — вообще люблю эту тему🔥
        <br/>
        Разрабатываю сценарии продающих вебинаров и готовлю экспертов к эфиру.
      </Typography>
    </Card>

    <div
        id="bottom-support"
        style="padding: 0 16px; background: #171717; border-top: 1px solid #2B2B2B; position: fixed; bottom: 0; left: 0; right: 0;"
    >
      <div class="main_container" style="padding: 0;">
        <Typography
            style="margin: 24px 0 24px 0"
            variant="v14-600"
            center
        >
          Возникли сложности?
          <AppLink
              color="#FF303A"
              @click="openExternalURL(store.state.user.directories.support_chat_link.link)"
          >
            Напишите боту
          </AppLink>
        </Typography>

        <AppButton
            style="margin-top: 8px; margin-bottom: 24px"
            size="large"
            full-width
            @click="onSubmit"
            :loading="isSubmitting"
            :disabled="isSubmitting"
        >
          Продолжить
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import Typography from '@/components/UI/Typography.vue'
import AppButton from '@/components/UI/AppButton.vue'
import FormTextInput from '@/components/form/FormTextInput.vue'
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store.js'
import {nextTick, onBeforeMount, onMounted, reactive} from 'vue'
import FormSelect from '@/components/form/FormSelect.vue'
import AppLink from "@/components/AppLink.vue"
import {openExternalURL} from "@/utils/openExternalURL.js"
import FormImageAvatar from '@/components/form/FormImageAvatar.vue'
import Card from '@/components/UI/Card.vue'
import Avatar from '@/components/profile/Avatar.vue'
import FormTextArea from '@/components/form/FormTextArea.vue'

const props = defineProps({
  nextStep: {
    type: Function,
    require: true,
  },
  createUserStepIfNotExist: {
    type: Function,
    require: true,
  },
})

const state = reactive({
  cityList: [],
  imagePreview: null,
  profile: null,
})

const {handleSubmit, setErrors, isSubmitting, setFieldValue, setValues, setFieldError, values} = useForm({
  validationSchema: Yup.object({
    avatar: Yup.mixed().test('required', 'Загрузите фотографию', (value) => state.imagePreview || value),
    first_name: Yup.string().trim().label('Имя').required(),
    last_name: Yup.string().trim().label('Фамилия').required(),
    email: Yup.string().trim().label('Электронная почта').required().email(),
    city: Yup.string().trim().label('Город').required(),
    about_me: Yup.string().trim().label('О себе').required().max(1000),
  }),
})

onMounted(async () => {
  const {profile} = store.state.user
  state.profile = profile
  if (profile.first_name || profile.last_name || profile.email || profile.city || profile.about_me) {
    setValues({
      first_name: store.state.user.profile.first_name,
      last_name: store.state.user.profile.last_name,
      email: store.state.user.profile.email,
      city: store.state.user.profile.city,
      about_me: store.state.user.profile.about_me,
    })
  }
  if(store.state.user.profile.preview_avatar) {
    state.imagePreview = store.state.user.profile.preview_avatar
  }
  state.cityList = store.state.user.directories.city_list.map(city => {
    return {
      value: city,
      label: city,
    }
  })
  requestAnimationFrame(() => {
    setErrors({
      avatar: null,
      first_name: null,
      last_name: null,
      email: null,
      city: null,
      about_me: null,
    })
    requestAnimationFrame(() => {
      setErrors({
        avatar: null,
        first_name: null,
        last_name: null,
        email: null,
        city: null,
        about_me: null,
      })
      requestAnimationFrame(() => {
        setErrors({
          avatar: null,
          first_name: null,
          last_name: null,
          email: null,
          city: null,
          about_me: null,
        })
      })
    })
  })
})

onBeforeMount( () => {
  const {profile} = store.state.user
  state.profile = profile

  if(store.state.user.profile.preview_avatar) {
    state.imagePreview = store.state.user.profile.preview_avatar
  }
})

const onSubmit = handleSubmit(async values => {
  const payload = {
    ...values,
  }
  await store.dispatch('user/updateProfile', payload)
  await props.nextStep()
})

</script>

<style lang="scss" scoped>

</style>
