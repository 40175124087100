<template>

  <div
      class="textInput"
      :class="{
        success: meta.valid,
        fullWidth: fullWidth,
        editable: editable,
      }"
      ref="textInput"
  >
    <label :for="name">
      {{ label }}
    </label>

    <div class="inputWrapper">
      <input
          ref="inputRef"
          :name="name"
          :value="state.value"
          :placeholder="placeholder"
          :type="type"
          :id="name"
          :readonly="!editable"
          :disabled="disabled"
          @focus="onFocus"
          @input="onInput"
          @blur="onBlur"
      />
      <Icon
          class="icon"
          :class="{dropDownShown: state.dropDownShown}"
          :icon="require(`@/assets/icons/white_24_arrowDown.svg`)"
      />
    </div>

    <div class="dropDownWrapper" ref="dropDownWrapper">
      <div
          v-show="state.dropDownShown && state.filteredOptions.length"
          class="dropDown"
          ref="dropDown"
      >
        <button
            @mousedown="(e) => e.preventDefault()"
            v-for="option in state.filteredOptions"
            class="menuItem"
            @click="selectOption(option)"
            :disabled="option.disabled"
        >
          {{ option.label }}
        </button>
      </div>
    </div>

    <div class="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
import {onMounted, onUnmounted, reactive, toRef, ref, watch, computed, nextTick} from 'vue'
import {useField} from 'vee-validate'
import Icon from '@/components/UI/Icon.vue'
import {useActiveElement, useDebounceFn} from '@vueuse/core'

const activeElement = useActiveElement()

const inputRef = ref(null)
const textInput = ref(null)
const dropDownWrapper = ref(null)
const dropDown = ref(null)

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  inputmode: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    default: [],
  },
  canBeEmpty: {
    type: Boolean,
    default: true,
  },
  editable: {
    type: Boolean,
    default: true,
  },
})

const state = reactive({
  value: '',
  filteredOptions: [],
  focused: true,
  dropDownShown: false,
})

const name = toRef(props, 'name')

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

const debouncedFilter = useDebounceFn((value) => {
  if (value && props.editable) {
    state.filteredOptions = props.options.filter(o => {
      return o.label.toLowerCase().includes(value.toLowerCase())
    })
  } else {
    state.filteredOptions = props.options
  }
}, 300)


watch(activeElement, (el) => {
  state.dropDownShown = inputRef.value === el || dropDown.value.contains(el);
})

watch(inputValue, value => {
  const found = props.options.find(option => option?.value?.toLowerCase() === value?.toLowerCase())
  if (found) {
    state.value = found.label
  } else {
    state.value = ''
  }
})

function onInput(e) {
  state.value = e.target.value
  debouncedFilter(e.target.value)
}

function onFocus(e) {
  const value = e.target.value
  if (value && props.editable) {
    state.filteredOptions = props.options.filter(o => {
      return o.label.toLowerCase().includes(value.toLowerCase())
    })
  } else {
    state.filteredOptions = props.options
  }
}

function onBlur(e) {
  const found = props.options.find(option => option.label.toLowerCase() === e.target.value.toLowerCase())
  if (found) {
    // console.log('onBlur found', found)
    state.value = found.label
    handleChange(found.value)
  } else {
    // console.log('onBlur clear')
    state.value = ''
    handleChange('')
  }
}

function selectOption(option) {
  // console.log('selectOption ', option)
  state.value = option.label
  handleChange(option.value)
  state.dropDownShown = false
  nextTick(() => {
    inputRef?.value?.blur()
  })
}

function onResize() {
  if (dropDown.value && dropDownWrapper.value) {
    dropDown.value.style.width = `${dropDownWrapper.value.offsetWidth}px`
  }
}

// function handleOutsideClick() {
//   const box = textInput.value;
//   if (box && !box.contains(event.target)) {
//     state.dropDownShown = false
//   }
// }

onMounted(() => {
  debouncedFilter()
  onResize()
  addEventListener("resize", onResize)
  // addEventListener("click", handleOutsideClick)
})

onUnmounted(() => {
  removeEventListener("resize", onResize)
  // removeEventListener("click", handleOutsideClick)
})

</script>

<style lang="scss" scoped>

@import "@/assets/variables";

.textInput {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &:not(.editable) {
    input {
      cursor: pointer;
      background: var(--background-paper-light-color);
    }
  }

  &.fullWidth {
    width: 100%;
  }

  label {
    color: $color-11;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }

  .inputWrapper {
    width: 100%;
    position: relative;

    input {
      width: 100%;
      border-radius: 12px;
      color: var(--text-secondary-color);
      border: 1px solid $color-13;
      font-size: 16px;
      font-weight: 400;
      height: 48px;
      padding: 14px 12px;

      &::placeholder {
        color: #838181;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
      }

      &:focus {
        border: 1px solid $color-5;
      }
    }
  }

  .inputError {
    border: 1px solid $color-5 !important;
  }

  .errorMessage {
    color: rgb(255, 48, 58);
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    min-height: 16px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .error_icon {
    position: absolute;
    top: calc(50%);
    right: 12px;
    transform: translateY(-50%);
    z-index: 100000;
  }
}

.TextInput input {
  color: $color-1;
  border: 1px solid $color-13;

  &:focus {
    border: 1px solid $color-5;
  }
}

//@media only screen and (max-width: 992px) {
//  .TextInput input {
//    height: 56px;
//    padding: 0px 16px;
//  }
//}

.icon {
  position: absolute;
  top: 12px;
  right: 12px;
  user-select: none;
  transition: transform .3s ease;
  transform: rotate(0) !important;
  pointer-events: none;

  &.dropDownShown.dropDownShown {
    transform: rotate(180deg) !important;
  }
}

.dropDownWrapper {
  position: relative;
  width: 100%;
}

.dropDown {
  position: absolute;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #4D4C4C;
  background: var(--background-paper-color);
  max-height: 176px;
  //max-height: 198px;
  overflow: auto;
}

.menuItem {
  background: var(--background-paper-color);
  display: block;
  min-height: 44px;
  color: var(--text-primary-color);
  border-bottom: 1px solid #4D4C4C;
  text-align: left;
  padding: 6px 12px;
  flex-grow: 0;
  flex-shrink: 0;

  font-size: 16px;
  font-weight: 400;

  &:disabled {
    opacity: .5;
  }

  &:first-child {
    border-radius: 12px 12px 0 0;
  }

  &:last-child {
    border-radius: 0 0 12px 12px;
    border-bottom: none;
  }

  &:hover {
    background: #393939;
  }

  &:active {
    background: #2C2B2B;
  }
}

</style>
