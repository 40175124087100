<template>
  <Card style="position: relative;">
    <div class="background"/>
    <div class="content">
      <Typography variant="v16-700" style="margin-bottom: 4px" one-line>
        {{ collection.title }}
      </Typography>
      <Typography
          variant="v14-500"
          color="#A6A5A5"
          style="margin-bottom: 16px"
          class="text3"
      >
        {{ collection.description }}
      </Typography>
      <div class="avatars">
        <div
            v-for="url in collection.avatar_previews"
            class="border"
        >
          <Avatar
              :image="imagesLinkCache.cache(url)"
              size="mini"
              round
          />
        </div>
        <Typography variant="v14-500" style="margin-left: 8px" v-if="state.additionalUserCount > 0">
          +{{ state.additionalUserCount }} {{ t('collection:PersonCount.', {count: state.additionalUserCount}) }}
        </Typography>
      </div>
    </div>
  </Card>
</template>

<script setup>
import Card from '@/components/UI/Card.vue'
import Typography from '@/components/UI/Typography.vue'
import Avatar from '@/components/profile/Avatar.vue'
import {imagesLinkCache} from '@/utils/imagesLinkCache.js'
import {declination} from '@/utils/declination.js'
import {onBeforeMount, reactive, watch} from 'vue'
import {t} from 'i18next'

const props = defineProps({
  collection: {
    type: Object,
  },
})

const state = reactive({
  additionalUserCount: 0,
})

onBeforeMount(() => {
  state.additionalUserCount = props.collection.user_count - props.collection.avatar_previews.length
})

</script>

<style lang="scss" scoped>

.text3 {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 54px;
  max-height: 54px;
}

.avatars {
  display: flex;
  align-items: center;

  & > :nth-child(1) {
    z-index: 5;
  }

  & > :nth-child(2) {
    z-index: 4;
    margin-left: -8px;
  }

  & > :nth-child(3) {
    z-index: 3;
    margin-left: -8px;
  }

  & > :nth-child(4) {
    z-index: 2;
    margin-left: -8px;
  }
}

.border {
  border: 2px solid #2A2323;
  background: #2C2323;
  border-radius: 32px;
}

.content {
  position: relative;
  z-index: 2;
}

.background {
  background-image: url("@/assets/images/collections/collection_backgorund.svg");
  background-size: cover;
  border-radius: 16px;
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  z-index: 1;
}

</style>
