<template>
  <div>
    <Typography
        variant="h2"
        style="margin-bottom: 8px"
    >
      Чем ты занимаешься?
    </Typography>

    <Typography
        variant="v14-400"
        style="margin-bottom: 24px"
        color="#A6A5A5"
    >
      Укажи свою профессиональную роль и нишу, чтобы другие знали, как с тобой взаимодействовать
    </Typography>

    <Typography
        variant="v24-700"
        style="margin-bottom: 8px"
    >
      Роль
    </Typography>

    <Typography
        variant="v14-400"
        style="margin-bottom: 8px"
        color="#A6A5A5"
    >
      Как ты себя позиционируешь? Можешь выбрать один или несколько пунктов
    </Typography>

    <FormError
        v-show="errors.specializationError"
        name="specializationError"
        style="margin-bottom: 8px"
    />

    <Card
        v-for="specialization in specializationsList"
        style="margin-bottom: 8px"
        :variant="errors.specializationError ? 'error' : 'secondary'"
    >
      <FormCircularCheckBox
          :name="`${specialization.id}`"
          :label="specialization.title"
          onlyInput
      />
    </Card>

    <Card
        :variant="errors.specializationError ? 'error' : 'secondary'"
    >
      <FormCircularCheckBox
          :name="`${specializationProfession.id}`"
          :label="specializationProfession.title"
          onlyInput
      />

      <template v-if="values[specializationProfession.id]">
        <FormError
            v-show="errors.professionError"
            style="margin-top: 16px; margin-bottom: 14px"
            name="professionError"
        />

        <FormOptionSelect
            style="margin-top: 14px"
            class="select"
            name="profession1"
            placeholder="Профессия"
            :options="state.filteredProfessionList1"
            :canAcceptAnyValue="true"
            onlyInput
            :error="errors.professionError"
        />

        <FormOptionSelect
            class="select"
            name="profession2"
            placeholder="Профессия"
            :options="state.filteredProfessionList2"
            :canAcceptAnyValue="true"
            onlyInput
            :error="errors.professionError"
        />

        <FormOptionSelect
            class="select"
            name="profession3"
            placeholder="Профессия"
            :options="state.filteredProfessionList3"
            :canAcceptAnyValue="true"
            onlyInput
            :error="errors.professionError"
        />
      </template>
    </Card>


    <Typography
        variant="v24-700"
        style="margin-bottom: 8px; margin-top: 24px"
    >
      Ниши
    </Typography>

    <Typography
        variant="v14-400"
        style="margin-bottom: 8px"
        color="#A6A5A5"
    >
      В каких нишах у тебя есть опыт работы?
    </Typography>

    <Card>
      <Typography
          variant="v12-400"
          style="margin-bottom: 8px"
          color="#A6A5A5"
      >
        Напиши свою нишу или выбери из списка
      </Typography>

      <FormError
          v-show="errors.error"
          style="margin-bottom: 12px"
          name="error"
      />

      <FormOptionSelect2
          class="select"
          name="niche1"
          placeholder="Например: продажи"
          :options="state.filteredNicheList1"
          :canAcceptAnyValue="true"
          only-input
          :error="errors.error"
      />

      <FormOptionSelect2
          v-show="values.niche1"
          class="select"
          name="niche2"
          placeholder="Например: финансы"
          :options="state.filteredNicheList2"
          :canAcceptAnyValue="true"
          only-input
          :error="errors.error"
      />

      <FormOptionSelect2
          v-show="values.niche2"
          class="select"
          name="niche3"
          placeholder="Например: инфобизнес"
          :options="state.filteredNicheList3"
          :canAcceptAnyValue="true"
          only-input
          :error="errors.error"
      />
    </Card>


    <Typography
        variant="v14-400"
        color="secondary"
        style="margin-bottom: 8px; margin-top: 24px"
    >
      Что указывают другие:
    </Typography>

    <Card>
      <div style="display: flex; gap: 8px ;align-items: center">
        <Avatar
            size="tiny"
            radius="8"
            :image="require(`@/assets/images/profile/avatar2.png`)"
        />
        <Typography
            variant="v18-700"
        >
          Анна Белова
        </Typography>
      </div>
      <BlockNiches
          style="margin: 16px 0 0; padding: 0;"
          :niches="[
            {id: -1, niche: {title: 'Криптовалюта'}},
            {id: -2, niche: {title: 'Здоровье'}},
            {id: -3, niche: {title: 'Психология'}},
          ]"
      />
    </Card>

    <div style="flex-grow: 1 "/>

    <div
        id="bottom-support"
        style="padding: 0 16px; background: #171717; border-top: 1px solid #2B2B2B; position: fixed; bottom: 0; left: 0; right: 0;"
    >
      <div class="main_container" style="padding: 0;">
        <Typography
            style="margin: 24px 0 24px 0"
            variant="v14-600"
            center
        >
          Возникли сложности?
          <AppLink
              color="#FF303A"
              @click="openExternalURL(store.state.user.directories.support_chat_link.link)"
          >
            Напишите боту
          </AppLink>
        </Typography>

        <AppButton
            style="margin-top: 8px; margin-bottom: 24px"
            size="large"
            full-width
            @click="onSubmit"
            :loading="isSubmitting"
            :disabled="isSubmitting"
        >
          Продолжить
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import Typography from "@/components/UI/Typography.vue"
import AppButton from "@/components/UI/AppButton.vue"
import {onBeforeMount, onMounted, reactive, watch} from "vue"
import store from "@/store/store.js"
import {useForm} from "vee-validate"
import * as Yup from "yup"
import FormImageAvatar from "@/components/form/FormImageAvatar.vue"
import {openExternalURL} from "@/utils/openExternalURL.js";
import AppLink from "@/components/AppLink.vue";
import Card from '@/components/UI/Card.vue'
import Avatar from '@/components/profile/Avatar.vue'
import FormOptionSelect from '@/components/form/FormOptionSelect.vue'
import FormCircularCheckBox from '@/components/form/FormCircularCheckBox.vue'
import FormError from '@/components/form/FormError.vue'
import {appAxios} from '@/axios.js'
import FormOptionSelect2 from '@/components/form/FormOptionSelect2.vue'
import BlockNiches from '@/components/BlockNiches.vue'

const allSpecializations = store.state.user.directories.specialization_list
const specializationsList = store.state.user.directories.specialization_list.filter(s => s.title !== 'Профессионал')
const specializationProfession = store.state.user.directories.specialization_list.find(s => s.title === 'Профессионал')


const state = reactive({
  isLoading: true,
  professionList: [],
  filteredProfessionList1: [],
  filteredProfessionList2: [],
  filteredProfessionList3: [],
  nicheList: [],
  filteredNicheList1: [],
  filteredNicheList2: [],
  filteredNicheList3: [],
})

const {handleSubmit, setErrors, isSubmitting, setFieldValue, setValues, errors, values} = useForm({
  validationSchema: Yup.object({
    profession1: Yup.string()
        .trim()
        .label('Профессия')
        .test('profession1', 'Профессия должна быть не более 40', (v) => {
          if (v) {
            const val = v.replace('|isNew|', '')
            return val.length <= 40
          }
          return true
        }),
    profession2: Yup.string()
        .trim()
        .label('Профессия')
        .test('profession2', 'Профессия должна быть не более 40', (v) => {
          if (v) {
            const val = v.replace('|isNew|', '')
            return val.length <= 40
          }
          return true
        }),
    profession3: Yup.string()
        .trim()
        .label('Профессия')
        .test('profession3', 'Профессия должна быть не более 40', (v) => {
          if (v) {
            const val = v.replace('|isNew|', '')
            return val.length <= 40
          }
          return true
        }),
    specializationError: Yup.mixed()
        .test('test1', 'Выбери хотя бы одну роль', () => {
          return allSpecializations.some(s => values[s.id])
        }),
    professionError: Yup.mixed()
        .test('test2', 'Заполни хотя бы одно поле', (_, context) => {
          const values = context.parent
          if (!values[specializationProfession.id]) {
            setErrors({
              professionError: null,
            })
            return true
          }
          return values[specializationProfession.id] && (values.profession1 || values.profession2 || values.profession3)
        }),

    niche1: Yup.string()
        .trim()
        .label('Ниша')
        .test('niche1', 'Ниша должна быть не более 40', (v) => {
          if (v) {
            const val = v.replace('|isNew|', '')
            return val.length <= 40
          }
          return true
        }),
    niche2: Yup.string()
        .trim()
        .label('Ниша')
        .test('niche2', 'Ниша должна быть не более 40', (v) => {
          if (v) {
            const val = v.replace('|isNew|', '')
            return val.length <= 40
          }
          return true
        }),
    niche3: Yup.string()
        .trim()
        .label('Ниша')
        .test('niche3', 'Ниша должна быть не более 40', (v) => {
          if (v) {
            const val = v.replace('|isNew|', '')
            return val.length <= 40
          }
          return true
        }),
    error: Yup.mixed()
        .test('error', 'Заполни хотя бы одно поле', (v) => {
          return values.niche1 || values.niche2 || values.niche3
        })
  }),
})

const props = defineProps({
  nextStep: {
    type: Function,
    require: true,
  },
  createUserStepIfNotExist: {
    type: Function,
    require: true,
  },
})

watch(values, (values) => {
  let used = {
    [values.profession1]: true,
    [values.profession2]: true,
    [values.profession3]: true,
  }

  state.filteredProfessionList1 = state.professionList.filter(profession => {
    if (values.profession1 === profession.value) {
      return true
    }
    return !used[profession.value]
  })

  state.filteredProfessionList2 = state.professionList.filter(profession => {
    if (values.profession2 === profession.value) {
      return true
    }
    return !used[profession.value]
  })

  state.filteredProfessionList3 = state.professionList.filter(profession => {
    if (values.profession3 === profession.value) {
      return true
    }
    return !used[profession.value]
  })

  used = {
    [values.niche1]: true,
    [values.niche2]: true,
    [values.niche3]: true,
  }

  let changed = false
  let newValues = {
    ...values,
  }

  if (!newValues.niche2 && newValues.niche3) {
    changed = true
    newValues = {
      niche1: newValues.niche1,
      niche2: newValues.niche3,
      niche3: newValues.niche2,
    }
  }

  if (!newValues.niche1 && newValues.niche2) {
    changed = true
    newValues = {
      niche1: newValues.niche2,
      niche2: newValues.niche1,
      niche3: newValues.niche3,
    }
  }

  if (changed) {
    setValues(newValues)
    return
  }

  state.filteredNicheList1 = state.nicheList.filter(niche => {
    if (values.niche1 === niche.value) {
      return true
    }
    return !used[niche.value]
  })

  state.filteredNicheList2 = state.nicheList.filter(niche => {
    if (values.niche2 === niche.value) {
      return true
    }
    return !used[niche.value]
  })

  state.filteredNicheList3 = state.nicheList.filter(niche => {
    if (values.niche3 === niche.value) {
      return true
    }
    return !used[niche.value]
  })
})

onBeforeMount(() => {
  state.professionList = [
    ...store.state.user.directories.profession_list.map(profession => {
      return {
        value: profession.id,
        label: profession.title,
      }
    }),
    ...store.state.user.profile.professions.map(userProfession => {
      return {
        value: userProfession.profession.id,
        label: userProfession.profession.title,
      }
    })
  ]
  state.nicheList = [
    ...store.state.user.directories.niche_list.map(niche => {
      return {
        value: niche.id,
        label: niche.title,
      }
    }),
    ...store.state.user.profile.niches.map(userNiche => {
      return {
        value: userNiche.niche.id,
        label: userNiche.niche.title,
      }
    })
  ]
})

onMounted(() => {
  const [p1, p2, p3] = store.state.user.profile.professions
  const specializations = store.state.user.profile.specializations
  let values = {}
  specializations.forEach(us => values[us.specialization.id] = true)

  const [p4, p5, p6] = store.state.user.profile.niches
  if (Object.keys(values).length || p1 || p2 || p3 || p4 || p5 || p6) {
    setValues({
      niche1: p4 ? p4.niche.id : undefined,
      niche2: p5 ? p5.niche.id : undefined,
      niche3: p6 ? p6.niche.id : undefined,
      profession1: p1 ? p1.profession.id : undefined,
      profession2: p2 ? p2.profession.id : undefined,
      profession3: p3 ? p3.profession.id : undefined,
      ...values,
    })
  }
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      setErrors({
        error: null,
      })
      requestAnimationFrame(() => {
        setErrors({
          error: null,
        })
        requestAnimationFrame(() => {
          setErrors({
            error: null,
          })
        })
      })
    })
  })
})

const onSubmit = handleSubmit(async values => {
  const selectedProfessions = []
  if (values.profession1) {
    selectedProfessions.push(values.profession1)
  }
  if (values.profession2) {
    selectedProfessions.push(values.profession2)
  }
  if (values.profession3) {
    selectedProfessions.push(values.profession3)
  }

  const specializations = store.state.user.profile.specializations
  const specializationsToRemove = []
  const specializationsToAdd = []

  allSpecializations.forEach(specialization => {
    const found = specializations.find(us => us.specialization.id === specialization.id)
    if (values[`${specialization.id}`]) {
      if (!found) {
        specializationsToAdd.push(specialization.id)
      }
    } else {
      if (found) {
        specializationsToRemove.push(found.id)
      }
    }
  })

  const professionsRes = await appAxios.profession.fetchAll()
  const userProfessions = professionsRes.data

  let toAdd = []
  let toRemove = []

  selectedProfessions.forEach(professionId => {
    const found = userProfessions.find(userProfession => userProfession.profession.id === professionId)
    if (found) {
      return
    }
    if (professionId.toString().includes('|isNew|')) {
      toAdd.push({
        title: professionId.replace('|isNew|', '')
      })
    } else {
      const found = store.state.user.directories.profession_list.find(p => p.id === professionId)
      if (found) {
        toAdd.push({
          title: found.title,
        })
      }
    }
  })

  userProfessions.forEach(userProfession => {
    const found = selectedProfessions.find(professionId => userProfession.profession.id === professionId)
    if (found) {
      return
    }
    toRemove.push(userProfession)
  })

  let promisesRemove = [
    ...toRemove.map(profession => appAxios.profession.remove(profession.id)),
    ...specializationsToRemove.map(id => appAxios.specializations.remove(id)),
  ]
  await Promise.all(promisesRemove)

  const promises = [
    ...toAdd.map(profession => appAxios.profession.create(profession.title)),
    ...specializationsToAdd.map(id => appAxios.specializations.add(id)),
  ]

  await Promise.all(promises)
  const [resProfession, resSpecializations] = await Promise.all([
    appAxios.profession.fetchAll(),
    appAxios.specializations.fetchAll()
  ])

  const selectedNiches = []
  if (values.niche1) {
    selectedNiches.push(values.niche1)
  }
  if (values.niche2) {
    selectedNiches.push(values.niche2)
  }
  if (values.niche3) {
    selectedNiches.push(values.niche3)
  }

  const nichesRes = await appAxios.niche.fetchAll()
  const userNiches = nichesRes.data

  toAdd = []
  toRemove = []

  selectedNiches.forEach(nicheId => {
    const found = userNiches.find(userNiche => userNiche.niche.id === nicheId)
    if (found) {
      return
    }
    if (nicheId.toString().includes('|isNew|')) {
      toAdd.push({
        title: nicheId.replace('|isNew|', '')
      })
    } else {
      const found = store.state.user.directories.niche_list.find(p => p.id === nicheId)
      if (found) {
        toAdd.push({
          title: found.title,
        })
      }
    }
  })

  userNiches.forEach(userNiche => {
    const found = selectedNiches.find(nicheId => userNiche.niche.id === nicheId)
    if (found) {
      return
    }
    toRemove.push(userNiche)
  })

  promisesRemove = [
    ...toRemove.map(item => appAxios.niche.remove(item.id)),
  ]
  await Promise.all(promisesRemove)
  const promisesAdd = [
    ...toAdd.map(item => appAxios.niche.create(item.title)),
  ]
  await Promise.all(promisesAdd)

  const res = await appAxios.niche.fetchAll()

  await store.commit('user/setProfile', {
    professions: resProfession.data,
    specializations: resSpecializations.data,
    niches: res.data,
  })

  await props.nextStep()
})

</script>

<style lang="scss" scoped>

.takePhone.takePhone {
  display: flex;
  margin-top: 16px;
  margin-bottom: 12px;
}

.edit_img {
  display: block;
  cursor: pointer;

  .profileImage {
    width: 106px;
    height: 106px;
    background-image: url("@/assets/images/profile/takePhoto.svg");

    .empty {
      background-image: url("@/assets/images/profile/takePhotoEmpty.svg");
    }
  }

  #user_img {
    display: none;
  }
}

.userAvatar {
  position: absolute;
  z-index: -2;
  width: 106px;
  height: 106px;
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;
}

.userAvatarUpdate {
  position: absolute;
  z-index: -1;
  width: 106px;
  height: 106px;
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;
}

</style>
