<template>
  <Card class="info" :class="{[variant]: variant}">
    <div class="header">
      <Icon class="icon" width="20" height="20" :icon="require(`@/assets/icons/user_20_learning.svg`)"/>

      <Typography variant="subtitle1">
        Образование
      </Typography>

      <AppButton
          v-if="isEdit"
          class="btn"
          size="small"
          variant="transparent"
          @click="() => router.push({name: 'EditEducations'})"
      >
        <Icon
            :icon="education[0] && (education[0].courses.length || education[0].communities.length || education[0].institutions.length) ? require(`@/assets/icons/gray_20_pen.svg`) : require(`@/assets/icons/red_20_add.svg`)"
        />
      </AppButton>
    </div>

    <div v-if="education[0] && (education[0].courses.length || education[0].communities.length || education[0].institutions.length)" class="tags">
      <template
          v-if="education[0]"
          v-for="educationType in educationTypeArray"
      >
        <template
            v-if="education[0][educationTypeToPlural(educationType)]"
            v-for="(item) in education[0][educationTypeToPlural(educationType)]"
        >
          <div class="tag">
            <Typography variant="text1" color="secondary">
              {{ getEducationName(educationType) }}
            </Typography>

            <Typography variant="body1" color="secondary" class="title">
              {{ item.title }}
              <template v-if="item.degree">
                - {{ item.degree }}
              </template>
            </Typography>

            <Typography v-if="item.ended_at" variant="body1" color="secondary">
              Год окончания: {{ item.ended_at.slice(0, 4) }}
            </Typography>

            <div
                v-if="item.images && item.images.length"
                class="images"
            >
              <template v-for="(i, index) in item.images">
                <div
                    class="image"
                    :style="{backgroundImage: urlToCssURL(imagesLinkCache.cache(i.image))}"
                    v-fullscreen-image="{
                      panoramaCurrentIndex: index,
                      imageUrl: item.images.map(img => img.image),
                    }"
                />
              </template>
            </div>
          </div>
        </template>
      </template>


    </div>

  </Card>
</template>

<script setup>
import Card from '@/components/UI/Card.vue'
import Icon from '@/components/UI/Icon.vue'
import Typography from '@/components/UI/Typography.vue'
import {reactive} from 'vue'
import {educationTypeArray, educationTypeToPlural, getEducationName} from '@/configs/educationType'
import {urlToCssURL} from '@/utils/urlToCssURL'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'

// "courses": [],
//     "communities": [],
//     "institutions": []

const props = defineProps({
  variant: {
    type: String,
    required: false,
  },
  education: {
    type: Object,
    default: {},
  },
  isEdit: {
    type: Boolean,
    default: false,
  }
})

const state = reactive({})
</script>

<style lang="scss" scoped>

.info {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.light {
    background: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  position: relative;

  .btn {
    position: absolute;
    top: -9px;
    right: -9px;
  }

  & > .icon {
    flex-shrink: 0;
  }
}

.tags {
  display: flex;
  flex-direction: column;
  width: 100%;

  .tag {
    padding: 12px 0;
    border-bottom: 1px solid #303030;
  }

  & > .tag:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.info.light {
  .tags {
    .tag {
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    }

    & > .tag:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.title {
  margin-top: 4px;
  margin-bottom: 8px;
}

.images {
  display: flex;
  width: 100%;
  gap: 8px;
  margin-top: 8px;

  .image {
    width: 44px;
    height: 56px;
    border-radius: 5px;
    background-color: #3B3B3B;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.tag > *:last-child {
  margin-bottom: 0;
}

</style>
