<template>
  <head-new
      :page_title="state.isCreate ? 'Добавить' : ct('Action.Edit')"
      :back-action="() => router.push({name: $route.meta.returnName ?? 'EditAchievements'})"
  />
  <div class="main_container form">

    <FormTextAreaGrowable
        class="textArea"
        label="Кейс или достижение"
        name="text"
        :rows="4"
    />

    <div class="buttons">
      <appButton
          full-width
          class="submitButton"
          :loading="isSubmitting"
          :disabled="isSubmitting"
          @click="onSubmit"
      >
        {{ ct('Action.Save') }}
      </appButton>

      <appButton
          full-width
          class="submitButton"
          :disabled="isSubmitting"
          variant="secondary"
          @click="onDelete"
      >
        {{ state.isCreate || achievementsCount <= 1 ? ct('Action.Cancel') : ct('Action.Delete') }}
      </appButton>
    </div>

    <BottomDrawer
        :is-shown="state.isDeleteId"
        title="Удаление данных"
        @close="state.isDeleteId = null"
    >
      <Typography variant="body1" color="secondary">
        Ты точно хочешь удалить кейс или достижение?
      </Typography>

      <div class="modalButtons">

        <appButton
            variant="secondary"
            @click="state.isDeleteId = null"
        >
          Оставить
        </appButton>

        <appButton
            variant="redSecondary"
            @click="deleteAchievement(state.isDeleteId)"
        >
          {{ ct('Action.Delete') }}
        </appButton>

      </div>

    </BottomDrawer>

  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {useForm} from 'vee-validate'
import * as Yup from 'yup'
import store from '@/store/store'
import {computed, onBeforeMount, onMounted, reactive, watch} from 'vue'
import {router} from '@/router/router'
import AppButton from '@/components/UI/AppButton.vue'
import FormTextArea from '@/components/form/FormTextArea.vue'
import {useRoute} from 'vue-router'
import {appAxios} from '@/axios'
import Typography from '@/components/UI/Typography.vue'
import BottomDrawer from '@/components/BottomDrawer.vue'
import FormTextAreaGrowable from "@/components/form/FormTextAreaGrowable.vue"
import {ct} from '@/locales/i18nextInit.js'

const route = useRoute()

const props = defineProps({
  achievementId: {
    type: String,
  }
})

const state = reactive({
  isCreate: route.meta.isCreate,
  isLoading: true,
  isDeleteId: null,
})

const {handleSubmit, values, isSubmitting, setFieldValue, setValues} = useForm({
  validationSchema: Yup.object({
    text: Yup.string().trim().label('Кейс или достижение').required().max(300),
  }),
})

const achievementsCount = computed(() => {
  return store.state.user.profile.achievements.length
})

onMounted(() => {
  if (props.achievementId) {
    const found = store.state.user.profile.achievements.find(item => item.id.toString() === props.achievementId.toString())
    setValues({
      text: found.title,
    })
  }
})

const onDelete = async function () {
  if (state.isCreate || achievementsCount.value <= 1) {
    return router.push({name: route.meta.isCheckList ? 'CheckListEditAchievements' : 'EditAchievements'})
  } else {
    const found = store.state.user.profile.achievements.find(item => item.id.toString() === props.achievementId.toString())
    state.isDeleteId = found?.id
  }
}

const onSubmit = handleSubmit(async values => {
  if (state.isCreate) {
    const res = await appAxios.achievements.create(values.text)
  } else {
    const found = store.state.user.profile.achievements.find(item => item.id.toString() === props.achievementId.toString())
    const res = await appAxios.achievements.update({
      ...found,
      title: values.text,
    })
  }
  const resResult = await appAxios.achievements.fetchAll()

  await store.commit('user/setProfile', {
    achievements: resResult.data,
  })

  await router.push({name: route.meta.isCheckList ? 'CheckListEditAchievements' : 'EditAchievements'})
})

async function deleteAchievement(id) {
  const found = store.state.user.profile.achievements.find(item => item.id.toString() === id.toString())
  const res = await appAxios.achievements.remove(found)

  const resResult = await appAxios.achievements.fetchAll()

  await store.commit('user/setProfile', {
    achievements: resResult.data,
  })

  state.isDeleteId = null
  await router.push({name: route.meta.isCheckList ? 'CheckListEditAchievements' : 'EditAchievements'})
}

</script>

<style lang="scss" scoped>

.form {
  margin-top: 24px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}

.modalButtons {
  display: flex;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 16px;

  & > * {
    flex-grow: 1;
  }
}

</style>
