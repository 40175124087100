<template>
  <template v-if="isLoading">
    <div class="chat">
      <Avatar
          is-loading
          size="small"
          round
      />
      <div class="content">

        <div class="textLine">
          <Skeleton
              :style="{maxWidth: `${100 + (Math.random() % 2) * 50 + (Math.random() * 20)}px`}"
              variant="text"
              height="18"
          />
        </div>

        <div class="textLine">
          <Skeleton
              :style="{maxWidth: `${150 + (Math.random() % 2) * 50 + (Math.random() * 20)}px`}"
              variant="text"
              height="14"
          />
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div
        v-if="(state.lastMessage || store.state.user.directories.support_chat_user.id === state.otherUser.id) && state.otherUser.id !== store.state.user.profile.id"
        class="chat"
        @click="() => router.push({name: 'UserChat', params: {userId: state.otherUser.id}})"
    >
      <Avatar
          class="avatar"
          :image="imagesLinkCache.cache(state.otherUser.preview_avatar)"
          size="small"
          round
      />
      <div class="content">

        <div class="textLine">
          <Typography class="text" variant="subtitle2">
            {{ state.otherUser.first_name }} {{ state.otherUser.last_name }}
          </Typography>

          <Typography v-if="state.unreadMessages > 0" class="meta" variant="text1" color="primary">
            {{ state.unreadMessages }}
          </Typography>
        </div>

        <div v-if="state.lastMessage" class="textLine">
          <Icon
              v-if="getIcon()"
              class="icon"
              width="20"
              height="20"
              :icon="getIcon()"
              style="margin-right: 4px; flex-shrink: 0"
          />
          <Typography
              class="text"
              variant="body1"
              :color="getColor()"
          >
            {{ makeMessage() }}
          </Typography>

          <Typography
              style="width: 48px;flex-shrink: 0;text-align: right"
              variant="body1"
              :color="state.unreadMessages > 0 ? '#fff' : undefined"
          >
            {{ state.time }}
          </Typography>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import store from '@/store/store'
import {onBeforeMount, reactive, watch} from 'vue'
import Avatar from '@/components/profile/Avatar.vue'
import {imagesLinkCache} from '@/utils/imagesLinkCache'
import Typography from '@/components/UI/Typography.vue'
import {router} from '@/router/router'
import moment from 'moment/moment'
import Skeleton from '@/components/UI/Skeleton.vue'
import Icon from '@/components/UI/Icon.vue'

const props = defineProps({
  chat: {
    type: Object,
  },
  isLoading: {
    type: Boolean,
  }
})

const state = reactive({
  userId: null,
  otherUser: {},
  unreadMessages: 0,
  time: null,
  lastMessage: null,
})

function makeMessage() {
  const predMessage = state.userId === state.lastMessage.sent_by_id ? 'Вы: ' : ''
  switch (state.lastMessage.message_type) {
    case 'message': {
      return `${predMessage}${state.lastMessage.message}`
    }
    case 'product': {
      if (state.lastMessage.message.includes('title') && state.lastMessage.message.includes('text') && state.lastMessage.message.includes('product')) {
        try {
          const json = JSON.parse(state.lastMessage.message)
          if(json.title && json.type === 'product') {
            return json.title
          }
        } catch (e) {
          // can't parse
        }
      }
      const predMessage = state.userId === state.lastMessage.sent_by_id ? 'Вы: ' : ''
      const topic = state.userId === state.lastMessage.sent_by_id ? `Я ищу ` : `Ищет `
      return `${predMessage}${topic}${state.lastMessage.message}`
    }
    case 'looking_for': {
      if (state.lastMessage.message.includes('title') && state.lastMessage.message.includes('text') && state.lastMessage.message.includes('type')) {
        try {
          const json = JSON.parse(state.lastMessage.message)
          if(json.title && json.type === 'looking_for') {
            return json.title
          }
        } catch (e) {
          // can't parse
        }
      }
      const topic = state.userId === state.lastMessage.sent_by_id ? `Я ищу ` : `Ищет `
      return `${predMessage}${topic}${state.lastMessage.message}`
    }
  }
}

function getIcon() {
  switch (state.lastMessage.message_type) {
    case 'message': {
      return null
    }
    case 'product': {
      return require(`@/assets/icons/user_20_showcase.svg`)
    }
    case 'looking_for': {
      return require(`@/assets/icons/user_20_search.svg`)
    }
  }
}

function getColor() {
  switch (state.lastMessage.message_type) {
    case 'message': {
      return state.unreadMessages > 0 ? '#fff' : undefined
    }
    case 'product': {
      return '#54C35F'
    }
    case 'looking_for': {
      return '#7EC9FF'
    }
  }
}

onBeforeMount(() => {
  if (props.isLoading) {
    return
  }

  const userId = store.state.user.profile.id
  state.userId = userId

  if (props.chat.user_from.id !== userId) {
    state.otherUser = props.chat.user_from
  }
  if (props.chat.user_to.id !== userId) {
    state.otherUser = props.chat.user_to
  }

  state.unreadMessages += props.chat.unchecked_messages_count

  if (props.chat.last_message) {
    state.lastMessage = props.chat.last_message
    const date = new Date(state.lastMessage.created_at)
    const today = new Date()
    const isToday = (today.toDateString() === date.toDateString())

    if (isToday) {
      state.time = moment(date).format('HH:mm')
    } else {
      state.time = moment(date).format('DD.MM')
    }
  }
})

watch(props, props => {
  const {chat} = props
  if (!chat) {
    return
  }
  state.unreadMessages = 0
  state.unreadMessages += props.chat.unchecked_messages_count

  if (props.chat.last_message) {
    state.lastMessage = props.chat.last_message
    const date = new Date(state.lastMessage.created_at)
    const today = new Date()
    const isToday = (today.toDateString() === date.toDateString())

    if (isToday) {
      state.time = moment(date).format('HH:mm')
    } else {
      state.time = moment(date).format('DD.MM')
    }
  }
})

</script>

<style lang="scss" scoped>

.chat {
  padding: 12px 16px;
  display: flex;
  cursor: pointer;
  gap: 8px;

  .content {
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    max-width: calc(100% - 64px);
  }

  .textLine {
    display: flex;
    align-items: center;

    .text {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .meta {
    flex-grow: 0;
    flex-shrink: 0;
    background: #FF303A;
    min-width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    border-radius: 20px;
    user-select: none;
  }

  .avatar {
    flex-grow: 0;
    flex-shrink: 0;
  }

  //&:first-child {
  //  padding-top: 0;
  //}
  //
  //&:last-child {
  //  padding-bottom: 0;
  //}

  & {
    border-bottom: #2B2B2B solid 1px;
  }
}
</style>
