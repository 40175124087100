<script setup>
import {onMounted, reactive, ref, toRef, watch} from 'vue'
import {useField} from 'vee-validate'
import {vMaska} from "maska"

const emit = defineEmits(['keyup', 'keydown'])

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  inputmode: {
    type: String,
    default: undefined,
  },
  textInfo: {
    type: String,
  },
  rows: {
    type: Number,
    default: 2,
  },
  maxRows: {
    type: Number,
    default: 4,
  },
  onlyInput: {
    type: Boolean,
    default: false,
  },
  growable: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  onKeydown: {
    type: Function,
    default: undefined,
  },
  // mask
  mask: {
    type: String,
    default: '',
  },
  pattern: {
    type: String,
    default: '',
  },
})


// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

</script>

<template>
  <div
      class="textInput"
      :class="{ 'has-error': !!errorMessage, success: meta.valid, fullWidth: props.fullWidth }"
  >
    <label v-if="!onlyInput" :for="name">
      {{ label }}
    </label>
    <div class="inputWrapper">

        <textarea
            :name="name"
            :id="name"
            :value="inputValue"
            :placeholder="placeholder"
            @input="handleChange"
            @blur="handleBlur"
            @keydown="onKeydown"
            :inputmode="inputmode"
            v-maska
            :data-maska="mask"
            :pattern="pattern"
            :rows="props.rows"
            :disabled="props.disabled"
        />
      <!--      <div class="error_icon" v-if="true">-->
      <!--        <img src="@/assets/images/error_input.svg" alt=""/>-->
      <!--      </div>-->
    </div>
    <!--    v-show="errorMessage || meta.valid"-->
    <div v-if="textInfo && !onlyInput" class="textInfo">
      {{ textInfo }}
    </div>
    <div v-if="!onlyInput" class="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/variables";

.textInput {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &.fullWidth {
    width: 100%;
  }

  label {
    color: $color-11;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }

  .inputWrapper {
    width: 100%;
    position: relative;

    textarea {
      display: block;
      width: 100%;
      border-radius: 12px;
      color: var(--text-secondary-color);
      border: 1px solid $color-13;
      font-size: 16px;
      font-weight: 400;
      //height: 48px;
      padding: 14px 12px;

      &::placeholder {
        color: #838181;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
      }

      &:focus {
        border: 1px solid $color-5;
      }
    }
  }

  .inputError {
    border: 1px solid $color-5 !important;
  }

  .errorMessage {
    color: rgb(255, 48, 58);
    font-size: 12px;
    font-weight: 500;
    min-height: 14px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .textInfo {
    color: #838181;
    font-size: 12px;
    font-weight: 500;
    min-height: 14px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .error_icon {
    position: absolute;
    top: calc(50%);
    right: 12px;
    transform: translateY(-50%);
    z-index: 100000;
  }
}

.TextInput input {
  color: $color-1;
  border: 1px solid $color-13;

  &:focus {
    border: 1px solid $color-5;
  }
}

.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}

.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";
  /* This is how textarea text behaves */
  white-space: pre-wrap;
  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}

.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;
  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}

.grow-wrap > textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  display: block;
  //width: 100%;
  border-radius: 12px;
  color: var(--text-secondary-color);
  border: 1px solid $color-13;
  font-size: 16px;
  font-weight: 400;
  //height: 48px;
  padding: 14px 12px;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

</style>
