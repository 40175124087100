<template>
  <div v-if="state.isReady" class="pageWrapper">
    <head-new
        :back-action="() => router.replace({name: 'SubscriptionPayment'})"
        title="Оформление подписки"
    />

    <div class="main_container pageWrapperOverflow">


      <FormTextInput
          name="email"
          label="Электронная почта"
      />

      <AppButton
          style="margin-top: 8px"
          size="large"
          full-width
          @click="onSubmit"
          :loading="isSubmitting"
          :disabled="isSubmitting"
      >
        {{ ct('Action.Save') }}
      </AppButton>


    </div>
  </div>
</template>

<script setup>
import HeadNew from "@/components/Head.vue"
import {useForm} from "vee-validate"
import * as Yup from "yup"
import store from "@/store/store.js"
import AppButton from '@/components/UI/AppButton.vue'
import {onBeforeMount, onMounted, reactive} from 'vue'
import {router} from '@/router/router.js'
import FormTextInput from '@/components/form/FormTextInput.vue'
import {ct} from '../../locales/i18nextInit.js'

const state = reactive({
  isReady: true,
})

const {handleSubmit, setErrors, isSubmitting, setFieldValue, setValues, values, setFieldError} = useForm({
  validationSchema: Yup.object({
    email: Yup.string().trim().label('Электронная почта').required().email(),
  }),
})

onBeforeMount(async () => {
  const raw = sessionStorage.getItem('SubscriptionPageSettings')
  if (!raw) {
    return router.push({name: 'SubscriptionTariffSelection'})
  }
  const json = JSON.parse(raw)
  const found = store.state.user.directories.subscription_price_list.find(i => {
    return i.id === json?.subscriptionPriceId
  })
  if (!found) {
    return router.push({name: 'SubscriptionTariffSelection'})
  }
  state.SubscriptionPageSettings = json
  state.email = json.email ?? store.state.user.profile.email
  state.isReady = true
})

onMounted(async () => {
  setValues({
    email: state.email,
  })
})

const onSubmit = handleSubmit(async values => {
  state.SubscriptionPageSettings.email = values.email
  sessionStorage.setItem('SubscriptionPageSettings', JSON.stringify(state.SubscriptionPageSettings))
  const payload = {
    ...values,
  }
  await store.dispatch('user/updateProfile', payload)
  await router.replace({name: 'SubscriptionPayment'})
})


</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.pageWrapper {
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  min-height: calc(100svh - 60px);
  max-height: calc(100svh - 60px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pageWrapperOverflow {
  overflow-y: auto;
  padding-bottom: 24px;
}


</style>
