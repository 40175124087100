<script setup>
import {computed, reactive, toRef} from 'vue'
import {useField} from 'vee-validate'
import {vMaska} from 'maska'
import moment from 'moment'

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'ДД.ММ.ГГГГ',
  },
  inputmode: {
    type: String,
    default: undefined,
  },
  textInfo: {
    type: String,
  },
})


// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name')

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
})

const state = reactive({
  bindedObject: {
    masked: '',
    unmasked: '',
    completed: false,
  }
})

// const djangoValue = computed(() => {
//   if (!state.bindedObject.completed) {
//     return null
//   }
//   const date = moment(state.bindedObject.masked, "DD.MM.YYYY")
//   return date.isValid() ? date.format('YYYY-MM-DD') : ''
// })

</script>

<template>
  <div
      class="textInput"
      :class="{ 'has-error': !!errorMessage, success: meta.valid, fullWidth: fullWidth }"
  >
    <label :for="name">
      {{ label }}
    </label>

    <div class="inputWrapper">
      <input
          :name="name"
          :id="name"
          :type="type"
          :value="inputValue"
          :placeholder="placeholder"
          @input="handleChange"
          @blur="handleBlur"
          :inputmode="inputmode"
          v-maska="state.bindedObject"
          data-maska="##.##.####"
      />
      <!--      <div class="error_icon" v-if="true">-->
      <!--        <img src="@/assets/images/error_input.svg" alt=""/>-->
      <!--      </div>-->
    </div>
    <!--    v-show="errorMessage || meta.valid"-->
    <div v-if="textInfo" class="textInfo">
      {{ textInfo }}
    </div>
    <div class="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/variables";

.textInput {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &.fullWidth {
    width: 100%;
  }

  label {
    color: $color-11;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }

  .inputWrapper {
    width: 100%;
    position: relative;

    input {
      width: 100%;
      border-radius: 12px;
      color: var(--text-secondary-color);
      border: 1px solid $color-13;
      font-size: 16px;
      font-weight: 400;
      height: 48px;
      padding: 14px 12px;

      &::placeholder {
        color: #838181;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
      }

      &:focus {
        border: 1px solid $color-5;
      }
    }
  }

  .inputError {
    border: 1px solid $color-5 !important;
  }

  .errorMessage {
    color: rgb(255, 48, 58);
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    min-height: 16px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .error_icon {
    position: absolute;
    top: calc(50%);
    right: 12px;
    transform: translateY(-50%);
    z-index: 100000;
  }
}

.TextInput input {
  color: $color-1;
  border: 1px solid $color-13;

  &:focus {
    border: 1px solid $color-5;
  }
}

.textInfo {
  color: #838181;
  font-size: 12px;
  font-weight: 500;
  min-height: 14px;
  line-height: 12px;
  margin-bottom: 4px;
}

@media only screen and (max-width: 992px) {
  .TextInput input {
    height: 56px;
    padding: 0 16px;
  }
}
</style>
