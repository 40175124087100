<template>

  <div
      class="textInput"
      :class="{
        success: meta.valid,
        fullWidth: fullWidth
      }"
      ref="textInput"
  >
    <label :for="name">
      {{ label }}
    </label>

    <div class="inputWrapper">
      <input
          ref="inputRef"
          :name="name"
          :value="state.value"
          :placeholder="placeholder"
          :type="type"
          :id="name"
          @input="onInput"
          @blur="onBlur"
          :class="{error, extra: state.newOption && state.value && state.newOption.value !== inputValue}"
      />
      <div class="inputAddons">
        <Icon
            v-if="state.value"
            class="clearIcon"
            :icon="require(`@/assets/icons/gray_24_modalClose.svg`)"
            @mousedown="selectOption()"
        />
        <AppButton
            v-if="state.newOption && state.value && state.newOption.value !== inputValue"
            size="small"
            variant="darkGray"
        >
          {{ ct('Action.Add') }}
        </AppButton>
      </div>
    </div>

    <div class="dropDownWrapper" ref="dropDownWrapper">
      <div v-show="state.dropDownShown && state.filteredOptions.length" class="dropDown" ref="dropDown">
        <template v-for="option in state.filteredOptions">
          <button
              @mousedown="(e) => e.preventDefault()"
              class="menuItem"
              @click="selectOption(option)"
              :class="{isNew: option.isNew}"
          >
            {{ option.label }}
          </button>
        </template>
      </div>
    </div>
    <div v-if="!onlyInput" class="errorMessage">
      {{ errorMessage }}
    </div>
    <div v-else v-show="errorMessage" class="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
import {onMounted, onUnmounted, reactive, toRef, ref, watch, nextTick} from 'vue'
import {useField} from 'vee-validate'
import Icon from '@/components/UI/Icon.vue'
import {useActiveElement, useDebounceFn} from '@vueuse/core'
import AppButton from "@/components/UI/AppButton.vue"
import {ct} from '../../locales/i18nextInit.js'

const document = window.document

const activeElement = useActiveElement()

const inputRef = ref(null)
const textInput = ref(null)
const dropDownWrapper = ref(null)
const dropDown = ref(null)

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  inputmode: {
    type: String,
    default: undefined,
  },
  //
  options: {
    type: Array,
    default: [],
  },
  canBeEmpty: {
    type: Boolean,
    default: true,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  canAcceptAnyValue: {
    type: Boolean,
    default: false,
  },
  onlyInput: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
})

const state = reactive({
  value: '',
  filteredOptions: [],
  additionalOptions: [],
  dropDownShown: false,
  newOption: null,
})

const name = toRef(props, 'name')

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

const filter = (value) => {
  if (!value || value?.length <= 2) {
    state.filteredOptions = []
    return
  }
  if (value) {
    state.filteredOptions = [
      ...props.options.filter(o => {
        return o.label.toLowerCase().includes(value.toLowerCase())
      }),
    ]
  } else {
    state.filteredOptions = [
      ...props.options,
    ]
  }
}

const debouncedFilter = useDebounceFn(filter, 300)

watch(() => props.options, () => {
  filter()
})

watch(inputValue, (inputValue) => {
  if (!inputValue) {
    return
  }
  if ((typeof inputValue === "string") && inputValue.includes('|isNew|')) {
    const value = inputValue.replace('|isNew|', '')
    if (!value !== state.value) {
      state.newOption = {
        label: value,
        value: `|isNew|${value}`,
        isNew: true,
      }
      state.value = value
      handleChange(inputValue)
    }
  } else {
    // ?
  }
})

watch(activeElement, (el) => {
  state.dropDownShown = inputRef.value === el || dropDown.value.contains(el);
})

watch(inputValue, value => {
  const found = [
    ...props.options,
    state.newOption ?? {},
  ].find(option => String(option?.value).toLowerCase().trim() === String(value).toLowerCase().trim())

  if (found) {
    state.value = found.label
  } else {
    state.value = ''
  }
})

function onInput(e) {
  if (!props.editable) {
    return
  }
  if (props.canAcceptAnyValue) {
    if (e.target.value && e.target.value.length >= 3) {
      const found = [
        ...props.options,
      ].find(option => String(option?.label?.toLowerCase().trim()) === String(e.target.value).toLowerCase().trim())

      if (found) {
        state.newOption = null
      } else {
        state.newOption = {
          label: e.target.value,
          value: `|isNew|${e.target.value}`,
          isNew: true,
        }
      }
    } else {
      state.newOption = null
    }
  }
  state.value = e.target.value
  debouncedFilter(e.target.value)
}

function onBlur(e) {
  const found = [
    ...props.options,
    state.newOption ?? {},
  ].find(option => option?.label?.toLowerCase().trim() === e.target.value.toLowerCase().trim())

  if (found) {
    selectOption(found)
  } else {
    selectOption(null)
  }
}

function selectOption(option) {
  if (!option) {
    state.value = ''
    state.newOption = ''
    handleChange('')
    return
  }
  state.value = option.label

  handleChange(option.value)
  state.newOption = ''
  state.dropDownShown = false
  nextTick(() => {
    inputRef?.value?.blur()
  })
}

function onResize() {
  if (dropDown.value && dropDownWrapper.value) {
    dropDown.value.style.width = `${dropDownWrapper.value.offsetWidth}px`
  }
}

onMounted(() => {
  filter()
  onResize()
  addEventListener("resize", onResize)
})

onUnmounted(() => {
  removeEventListener("resize", onResize)
})

</script>

<style lang="scss" scoped>

@import "@/assets/variables";

.textInput {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &.fullWidth {
    width: 100%;
  }

  label {
    color: $color-11;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }

  .inputWrapper {
    width: 100%;
    position: relative;

    input {
      width: 100%;
      border-radius: 12px;
      color: var(--text-secondary-color);
      border: 1px solid $color-13;
      font-size: 16px;
      font-weight: 400;
      height: 48px;
      padding: 14px 38px 14px 12px;
      overflow: hidden;
      text-overflow: ellipsis;

      &.extra {
        padding-right: 150px !important;
      }

      &.error {
        border: 1px solid #FF303A;
      }

      &::placeholder {
        color: #838181;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
      }

      &:focus {
        border: 1px solid $color-5;
      }
    }
  }

  .inputError {
    border: 1px solid $color-5 !important;
  }

  .errorMessage {
    color: rgb(255, 48, 58);
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    min-height: 16px;
    line-height: 12px;
    margin-bottom: 4px;
  }

  .error_icon {
    position: absolute;
    top: calc(50%);
    right: 12px;
    transform: translateY(-50%);
    z-index: 100000;
  }
}

.TextInput input {
  color: $color-1;
  border: 1px solid $color-13;

  &:focus {
    border: 1px solid $color-5;
  }
}

.inputAddons {
  position: absolute;
  top: 6px;
  right: 6px;
  user-select: none;
  display: flex;
  gap: 6px;
}

.clearIcon {
  position: relative;
  top: 6px;
  margin: 0 6px;
  //right: 12px;
  user-select: none;
  transition: transform .3s ease;
  transform: rotate(0) !important;
  cursor: pointer;
}

.dropDownWrapper {
  position: relative;
  width: 100%;
}

.dropDown {
  position: absolute;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid #4D4C4C;
  background: var(--background-paper-color);
  max-height: 176px;
  //max-height: 198px;
  overflow: auto;
  width: 100% !important;
}

.menuItem {
  background: var(--background-paper-color);
  display: block;
  min-height: 44px;
  color: var(--text-primary-color);
  border-bottom: 1px solid #4D4C4C;
  text-align: left;
  padding: 6px 12px;
  flex-grow: 0;
  flex-shrink: 0;

  font-size: 16px;
  font-weight: 400;

  &.isNew {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &:first-child {
    border-radius: 12px 12px 0 0;
  }

  &:last-child {
    border-radius: 0 0 12px 12px;
    border-bottom: none;
  }

  &:hover {
    background: #393939;
  }

  &:active {
    background: #2C2B2B;
  }
}

</style>
