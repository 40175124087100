<template>
  <div
      v-if="state.isShown"
      style="min-height: 36px; margin-top: 8px;"
  >
    <Typography
        v-if="!state.isLoading"
        variant="title"
        style="margin: 4px 0 4px"
    >
      {{ t('collection:Home.Title') }}
    </Typography>
    <Skeleton
        v-else
        width="100%"
        height="20"
        style="margin: 10px 0;"
    />

    <Typography
        v-if="!state.isLoading"
        variant="v14-400"
        color="#A6A5A5"
        style="margin-bottom: 16px"
    >
      {{ t('collection:Home.Text') }}
    </Typography>
    <div
        v-else
        style="margin-bottom: 16px"
    >
      <Skeleton
          width="100%"
          height="14"
          style="margin: 4px 0; display: block"
      />
      <Skeleton
          width="270"
          height="14"
          style="margin: 4px 0; display: block"
      />
    </div>

    <template v-if="state.isLoading">
      <Skeleton
          width="100%"
          height="160"
      />
    </template>
    <template v-else>
      <template v-if="state.collections.length === 0">

      </template>
      <template v-if="state.collections.length === 1">
        <CollectionAuthorCard
            :collection="state.collections[0]"
            @click="() => router.push({name: 'Collection', params: {collectionId: state.collections[0].id}})"
        />
      </template>
      <template v-if="state.collections.length > 1">
        <swiper
            :slidesPerView="'auto'"
            :spaceBetween="8"
            :freeMode="true"
            class="collectionsSwiper"
        >
          <swiper-slide v-for="item of state.collections" class="slide">
            <CollectionAuthorCard
                :collection="item"
                @click="() => router.push({name: 'Collection', params: {collectionId: item.id}})"
            />
          </swiper-slide>
        </swiper>
      </template>
    </template>
  </div>
</template>

<script setup>
import {router} from '@/router/router.js'
import Typography from '@/components/UI/Typography.vue'
import {Swiper, SwiperSlide} from 'swiper/vue'
import {onBeforeMount, reactive} from 'vue'
import CollectionAuthorCard from '@/views/collections/CollectionAuthorCard.vue'
import Skeleton from '@/components/UI/Skeleton.vue'
import {appAxios} from '@/axios.js'
import {t} from 'i18next'

const state = reactive({
  isLoading: true,
  isShown: true,
  collections: [],
})

onBeforeMount(async () => {
  state.isLoading = true

  const res = await appAxios.collections.fetchAll()
  state.collections = res.data

  if (state.collections.length === 0) {
    state.isShown = false
  }
  state.isLoading = false
})

</script>

<style lang="scss" scoped>

.collectionsSwiper {
  overflow: visible;
  height: 160px;
}

.slide {
  width: calc(70%);
  min-width: 290px;
}

</style>
